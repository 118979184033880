import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./myBeShowNavbar.css";

const MyBeShowNavbar = ({ children }) => {
  const location = useLocation();
  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setShowNavBar(false);
    } else if (location.pathname === "/dashboard") {
      setShowNavBar(false);
    } else if (location.pathname === "/admin_dashboard") {
      setShowNavBar(false);
    } else if (location.pathname === "/create-user") {
      setShowNavBar(false);
    } else {
      setShowNavBar(true);
    }
  }, [location]);

  return (
    <div className="common_page_sticky_navbar">{showNavBar && children}</div>
  );
};

export default MyBeShowNavbar;
