import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import imgTop from "../assets/images/apligrampanchayat-top.png";
import loginMap from "../assets/images/mapMaharastra.png";
import Data from "../../Data.json";
import "../login/Login.css";

const AdminLogin = () => {
  const [userError, setUserError] = useState("");
  const [passError, setPassError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("info") != null) {
      console.log("User is already logged in");
      navigate("/");
    }
  }, []);
  const [formData, setFormData] = useState({
    user: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //   post method

  // const loginData = async (e) => {
  //   e.preventDefault();
  //   const { user, password } = formData;
  //   if (user == null || user === "") {
  //     setUserError("User Is Require");
  //   } else {
  //     setUserError("");
  //   }
  //   if (password == null || password === "") {
  //     setPassError("Password Is Required.");
  //   }
  //   else{
  //     setPassError('')
  //   }
  //   try {
  //     const finalData = { login_id: user, password: password };
  //     const response = await axios.post(
  //       "https://microdynamicsoftware.uk/gram_panchayat/user/login",
  //       finalData,
  //       {
  //         auth: {
  //           username: "grampanchayat",
  //           password: "t5AK6/mFx[`7chzJ",
  //         },
  //       }
  //     );
  //     console.log(response.data.data);
  //     if (response.data.status) {
  //       sessionStorage.setItem("info", JSON.stringify(response.data.data));
  //       // window.alert(response.data.message);
  //       navigate("/");
  //     } else {
  //       alert(response.data.error);
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response.data.data);
  //       console.log(error.response.status);
  //     }
  //   }
  // };
  return (
    <React.Fragment>
      <Helmet>
        <title>Admin लॉगिन | ग्रामपंचायत</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row  loginMainSection">
          <div className="col-lg-12 col-md-12 col-sm-12 ps-0 pe-0">
            <div className="img-top-se">
              <img
                src={imgTop}
                alt="loginTopImage"
                className="img-fluid w-100"
              />
            </div>
          </div>
          <div className="main-section mt-5 d-flex justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-12 text-center">
              <div className="map-sec">
                {/* <h1 className='map-text ms-5'> <span className='text-orange'>{Data.image_heading_one}</span> <span className='text-green'>{Data.image_heading_two}</span></h1> */}
                <img
                  src={loginMap}
                  alt="loginMap"
                  className="loginMap img-fluid"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center">
              <div className="login-sec">
                <h1 className="login-text text-center mb-4">
                  ई-ग्रामपंचायत Admin Login
                </h1>
                <div
                  className="card main-login-card"
                  style={{ width: "35rem", border: "white" }}
                >
                  <div className="card-body login-card">
                    <form>
                      <div className="form-group text-center mt-4 mb-5">
                        <h3 className="user-login-text">
                          ग्रामपंचायत Admin Login
                        </h3>
                      </div>
                      <div className="form-group mt-5 mb-4">
                        <input
                          type="text"
                          name="user"
                          value={formData.user}
                          onChange={handleChange}
                          className="form-control login-input"
                          placeholder={Data.placeholder_user}
                        />
                        <span className="text-danger ms-2 fw-bold">
                          {" "}
                          {userError}
                        </span>
                      </div>
                      <div className="form-group mb-4">
                        <input
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control login-input"
                          placeholder={Data.placeholder_password}
                        />
                        <span className="text-danger ms-2 fw-bold">
                          {" "}
                          {passError}
                        </span>
                      </div>
                      <div
                        className="form-group mb-4"
                        onClick={() => navigate("/admin_dashboard")}
                      >
                        <button className="btn btn-primary login-btn form-control login-input">
                          {Data.Login}
                        </button>
                        {/* <span className="text-danger"> {error}</span> */}
                      </div>
                      <div className="form-group float-end mb-4">
                        <Link to="#">
                          <span className="password-text text-primary">
                            {Data.passwordLink}
                          </span>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12">
            <div className="login-footer">
              <h6 className="footer-text">
                {Data.login_footer_link1} &#169; {Data.login_footer_link2}{" "}
              </h6>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminLogin;
