import React, { useEffect, useState } from "react";
import "../banner/homeBanner/homeBanner.css";
import "../contact/contact.css";
import "../ourGrampanchayat/ourGram.css";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SocialLinks = () => {
  const [bannerData, setBannerData] = useState([]);

  const [socialLinksData, setSocialLinksData] = useState({
    facebookUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
    youtubeUrl: "",
  });

  const [errors, setErrors] = useState({
    facebookUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
    youtubeUrl: "",
  });

  const handleSocialLinksData = (e) => {
    const { name, value } = e.target;
    setSocialLinksData({ ...socialLinksData, [name]: value });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        value.trim() !== ""
          ? ""
          : `${name.charAt(0).toUpperCase() + name.slice(1)} is required *`,
    }));
  };

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  // validation

  const validation = () => {
    let valid = true;
    const newErrors = {
      facebookUrl: "",
      instagramUrl: "",
      linkedinUrl: "",
      youtubeUrl: "",
    };

    // map url validation
    if (socialLinksData.mapUrl.trim() === "") {
      newErrors.mapUrl = "कृपया नकाशा url टाका*";
      valid = false;
    }

    // address validation
    if (socialLinksData.address.trim() === "") {
      newErrors.address = "कृपया पत्ता टाका*";
      valid = false;
    }

    // mobile validation
    if (socialLinksData.mobile.trim() === "") {
      newErrors.mobile = "कृपया मोबाइल नंबर टाका*";
      valid = false;
    }

    // mobile validation
    if (socialLinksData.email.trim() === "") {
      newErrors.email = "कृपया ईमेल टाका*";
      valid = false;
    }

    // mobile validation
    if (socialLinksData.webLink.trim() === "") {
      newErrors.webLink = "कृपया वेबसाइट लिंक टाका*";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    handleListData();
  }, []);

  const handleRefresh = async () => {
    await handleListData();
  };

  //   home banner api

  const handleListData = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/get_social_link",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("social links response", response.data);
      setBannerData(response.data.data);
      setSocialLinksData({
        facebookUrl: response.data.data.facebook,
        instagramUrl: response.data.data.instagram,
        linkedinUrl: response.data.data.linkedin,
        youtubeUrl: response.data.data.youtube,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // banner add  api

  const handleAddBanner = async (e) => {
    e.preventDefault();
    // if (validation()) {
    // }
    try {
      const { facebookUrl, instagramUrl, linkedinUrl, youtubeUrl } =
        socialLinksData;
      const data = {
        admin_id: userDetails.id,
        facebook: facebookUrl,
        instagram: instagramUrl,
        linkedin: linkedinUrl,
        youtube: youtubeUrl,
      };
      console.log("update social links finalData", data);

      const response = await axios.post(
        API_BASE_URL + "api/update_social_link",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("update contact response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        const data = response.data.data;
        setBannerData(data);
        handleRefresh();
        // setSocialLinksData({
        //   mapUrl: "",
        //   address: "",
        //   mobile: "",
        //   email: "",
        //   webLink: "",
        // });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occurred in add banner", error);
    }
  };

  return (
    <div className="container">
      <div className="row h-100">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="banner_wrapper">
            <div className="banner_header_top">
              <h4 style={{ fontFamily: "Poppins" }}>Social Links</h4>
            </div>
            <div className="banner_body">
              <form onSubmit={handleAddBanner}>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label
                        className="input_label"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Facebook Url :-
                      </label>
                      <input
                        type="text"
                        name="facebookUrl"
                        value={socialLinksData.facebookUrl}
                        onChange={handleSocialLinksData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.mapUrl && (
                        <p className="banner_error_text">{errors.mapUrl}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label
                        className="input_label"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Instagram Url :-
                      </label>
                      <input
                        type="text"
                        name="instagramUrl"
                        value={socialLinksData.instagramUrl}
                        onChange={handleSocialLinksData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.address && (
                        <p className="banner_error_text">{errors.address}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label
                        className="input_label"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Linkedin Url :-
                      </label>
                      <input
                        type="text"
                        name="linkedinUrl"
                        value={socialLinksData.linkedinUrl}
                        onChange={handleSocialLinksData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.mobile && (
                        <p className="banner_error_text">{errors.mobile}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label
                        className="input_label"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Youtube Url :-
                      </label>
                      <input
                        type="text"
                        name="youtubeUrl"
                        value={socialLinksData.youtubeUrl}
                        onChange={handleSocialLinksData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.email && (
                        <p className="banner_error_text">{errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="banner_input_group d-flex justify-content-end mt-4 mb-4">
                  <button className="input_add_btn">माहिती बदला</button>
                </div>
              </form>
              <ul className="banner_ul_list">
                {bannerData && Object.keys(bannerData).length > 0 ? (
                  <li className="banner_list">
                    <div className="description_our_village_sec">
                      <h6
                        className="fw-semibold"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Facebook Url :-
                      </h6>
                      <p className="contact_data_para">{bannerData.facebook}</p>
                    </div>
                    <div className="description_our_village_sec">
                      <h6
                        className="fw-semibold"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Instagram Url :-
                      </h6>
                      <p className="contact_data_para">
                        {bannerData.instagram}
                      </p>
                    </div>
                    <div className="description_our_village_sec">
                      <h6
                        className="fw-semibold"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Linkedin Url :-
                      </h6>
                      <p className="contact_data_para">{bannerData.linkedin}</p>
                    </div>
                    <div className="description_our_village_sec">
                      <h6
                        className="fw-semibold"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Youtube Url :-
                      </h6>
                      <p className="contact_data_para">{bannerData.youtube}</p>
                    </div>
                  </li>
                ) : (
                  <li className="banner_list">No banner available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default SocialLinks;
