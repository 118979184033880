import React from "react";
import "../footer/Footer.css";
import flag from "../../component/assets/images/flag-indian.png";

const Footer = () => {
  return (
    <div className="footer footer_bg">
      <p className="footer_project_dev_info_text">
        developed and designed by microdynamic software pvt.ltd
      </p>
      <h6 className="footer_right_side_text">
        <img src={flag} width={25} className="img-fluid me-2" alt="flag" /> सर्व
        अधिकार © ई-ग्रामपंचायत विभाग महाराष्ट्र राज्य
      </h6>
    </div>
  );
};

export default Footer;
