import React, { useState } from "react";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddImages = () => {
  const navigate = useNavigate("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleError, setRoleError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [displayOnHome, setDisplayOnHome] = useState("Y");
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("Y");

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleDisplayOnHome = (e) => {
    setDisplayOnHome(e.target.value);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    setErrorMessage("");
  };

  //   const handleProductDescription = (e, editor) => {
  //     const data = editor.getData();
  //     setDescription(data);
  //   };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          console.log("img width", img.width);
          console.log("img height", img.height);
          setSelectedImage(e.target.result);
          // setTimeout(() => {
          //   if (img.width >= 250 && img.width <= 443 && img.height === 319) {
          //     console.log("img width", img.width);
          //     console.log("img height", img.height);
          //     setSelectedImage(e.target.result);
          //     setErrorMessage("");
          //   } else {
          //     setErrorMessage(
          //       `कृपया Images ची hight हि 319 च असावी आणि width हि 250 पेक्षा जास्त आणि 433 पेक्षा कमी असावी`
          //     );
          //     setTimeout(() => {
          //       alert(
          //         `तुमी select केलेली height ${img.height} आणि width ${img.width}`
          //       );
          //     }, 2000);
          //   }
          // }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // handle Add schemes api

  const handleAddMembers = async (e) => {
    e.preventDefault();
    if (errorMessage) {
      alert(errorMessage);
    } else {
      try {
        const data = {
          admin_id: userDetails.id,
          image: selectedImage,
          youtube_url: "",
          type: "image", //pass image or url
          status: status,
        };
        console.log("final data of add image", data);
        const response = await axios.post(
          API_BASE_URL + "api/add_photo_video",
          data,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("add image response", response.data);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/photo-video-list");
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log("error occurred in add image", error);
      }
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="input_wrappers">
            <div className="wrapper_title">
              <h4>अ‍ॅड इमेज</h4>
            </div>
            <form onSubmit={handleAddMembers}>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">इमेज :-</label>
                </div>
                <div className="input_sec">
                  {selectedImage ? (
                    <div className="selected_img_group">
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/webp"
                        onChange={handleImageChange}
                        className={`form-control common_input`}
                      />
                      {errorMessage && (
                        <p className="banner_error_text">{errorMessage}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">शीर्षक :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="common_input form-control"
                  />
                  {titleError && (
                    <span className="banner_error_text">{titleError}</span>
                  )}
                </div>
              </div> */}
              {/* <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">पदाचे नाव :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    className="common_input form-control"
                  />
                  {roleError && (
                    <span className="banner_error_text">{roleError}</span>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">मोबाईल नं :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="common_input form-control"
                  />
                  {mobileNumberError && (
                    <span className="banner_error_text">
                      {mobileNumberError}
                    </span>
                  )}
                </div>
              </div> */}
              {/* <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">होमपेजला दाखवा :-</label>
                </div>
                <div className="input_sec">
                  <div className="status-group">
                    <div className="active-sec">
                      <input
                        type="radio"
                        name="displayOnHome"
                        value="Y"
                        checked={displayOnHome === "Y"}
                        onChange={handleDisplayOnHome}
                        className="me-2"
                      />
                      <span>होय</span>
                    </div>
                    <div className="deactive-sec">
                      <input
                        type="radio"
                        name="displayOnHome"
                        value="N"
                        checked={displayOnHome === "N"}
                        onChange={handleDisplayOnHome}
                        className="me-2"
                      />
                      <span>नाही </span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">स्टेटस :-</label>
                </div>
                <div className="input_sec">
                  <div className="status-group">
                    <div className="active-sec">
                      <input
                        type="radio"
                        name="status"
                        value="Y"
                        checked={status === "Y"}
                        onChange={handleStatus}
                        className="me-2"
                      />
                      <span>अ‍ॅक्टिव</span>
                    </div>
                    <div className="deactive-sec">
                      <input
                        type="radio"
                        name="status"
                        value="N"
                        checked={status === "N"}
                        onChange={handleStatus}
                        className="me-2"
                      />
                      <span>डीअ‍ॅक्टिव</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input_group">
                <button className="input_add_btn">Add</button>
              </div>
            </form>
            {/* <div className="note_sec text-center">
              <p className="note_para">
                Note :-{" "}
                <span className="note_span">
                  कृपया Images ची hight हि 319 च असावी आणि width हि 250 पेक्षा
                  जास्त आणि 433 पेक्षा कमी असावी
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default AddImages;
