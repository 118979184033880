import React, { useEffect, useState } from "react";
import style from "../members/members.module.css";
import { Link } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import "../members/member.css";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ImportantPlaces = () => {
  const [search, setSearch] = useState("");
  const [schemeList, setSchemeList] = useState([]);
  const [displayOnHome, setDisplayOnHome] = useState("Y");
  const [isDialogOpen, setDialogOpen] = useState("");
  const [isUrlDialogOpen, setIsUrlDialogOpen] = useState("");
  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleDisplayOnHome = (e) => {
    setDisplayOnHome(e.target.value);
  };

  const handleViewAddressClick = (id) => {
    setDialogOpen(id);
  };

  const handleViewUrlClick = (id) => {
    setIsUrlDialogOpen(id);
  };

  const handleCloseDialog = () => {
    setDialogOpen(null);
    setIsUrlDialogOpen(null);
  };

  //   imp place list api

  const handlePlacesList = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      const response = await axios.post(
        API_BASE_URL + "api/important_places",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("imp place list response", response.data);
      setSchemeList(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    handlePlacesList();
  }, []);

  const handleRefresh = async () => {
    await handlePlacesList();
  };

  // Scheme Set Visible on dashboard

  const handleVisibleOnDashboard = async (place_id, display_on_home) => {
    try {
      const finalData = {
        place_id: place_id,
        display_on_home: display_on_home,
      };
      console.log("place Set Visible on dashboard finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/important_place_visible_on_home",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("place Set Visible on dashboard", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
      }
      handleRefresh();
      // setSchemeList(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  // handle delete scheme

  const handleDelete = async (id) => {
    var check = window.confirm("Are Your Sure To Delete Important Place Item");
    if (!check) {
      return;
    }
    const finalData = {
      place_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/delete_important_place",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured deleting data", error);
    }
  };

  const filterCat = schemeList.filter((elem) => {
    return elem.title.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className="container h-100">
      <div className="row">
        <div className="col-lg-12">
          <div className="today_order_table">
            <div className="table_body_order">
              <div className="row">
                <div className="col-lg-4">
                  <div className="cart_heading">
                    <h6 className="today_heading">सर्व प्रमुख स्थळे</h6>
                    <div className="show_entreis">1</div>
                    <h6 className="show_entry_heading">Show entries</h6>
                  </div>
                </div>
                <div className="col-lg-8 d-flex justify-content-end align-items-center gap-3">
                  <div className="bulk_btn">
                    {/* <Link
                      to="/add_schemes"
                      className={`btn ${style.add_new_btn}`}
                    >
                      Add Banner
                    </Link> */}
                  </div>
                  <div className="bulk_btn">
                    <Link
                      to="/add-imp-places"
                      className={`btn ${style.add_new_btn}`}
                    >
                      Add
                    </Link>
                  </div>

                  <div className="today_order_Search me-1">
                    <input
                      type="search"
                      value={search}
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control search_input1"
                    />
                    {!search && <IoIosSearch className="search_icon1" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <table className="table table-hover mt-3">
            <thead>
              <tr className={`${style.main_table_head}`}>
                <th scope="col">अ.क्रं</th>
                <th scope="col"> फोटो </th>
                <th scope="col"> मंदिरा चे नाव </th>
                <th scope="col"> वर्णन </th>
                <th scope="col"> नकाशा </th>
                <th scope="col">होमपेजला दाखवा</th>
                <th scope="col">स्टेटस</th>
                <th scope="col">क्रिया</th>
              </tr>
            </thead>
            <tbody className="main_body_table">
              {filterCat.length > 0 ? (
                filterCat.map((ele, index) => {
                  return (
                    <tr key={ele.id}>
                      <th scope="row">
                        <div className={style.product_title_on_middle}>
                          {index + 1}
                        </div>
                      </th>
                      <td className="d-flex justify-content-center align-items-center">
                        <div className={style.img_width}>
                          <img
                            src={ele.image}
                            className="img-fluid"
                            alt="schemes"
                          />
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          {ele.title}
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <button
                            className="view_address"
                            onClick={() => {
                              handleViewAddressClick(ele.id);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <button
                            className="view_address"
                            onClick={() => {
                              handleViewUrlClick(ele.id);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <Dialog
                        open={isDialogOpen === ele.id}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>Description</DialogTitle>
                        <DialogContent style={{ paddingBottom: "0px" }}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: ele.description,
                            }}
                          />
                          {/* <p>{ele.description}</p> */}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={isUrlDialogOpen === ele.id}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>Map Url</DialogTitle>
                        <DialogContent style={{ paddingBottom: "0px" }}>
                          <iframe
                            src={ele.map_url}
                            width="500"
                            height="400"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="map-url"
                          ></iframe>
                          {/* <p>{ele.map_url}</p> */}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <input
                            type="checkbox"
                            checked={ele.display_on_home === "Y"} // Assuming ele.display_on_home is 'Y' or 'N'
                            onChange={() =>
                              handleVisibleOnDashboard(
                                ele.id,
                                ele.display_on_home === "Y" ? "N" : "Y"
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          {ele.status === "Y" ? (
                            <span className="active_status">Active</span>
                          ) : (
                            <span className="dective_status">Deactive</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <Link
                            to={`/edit-imp-places/${ele.id}`}
                            // onClick={() => handleEditNavigation()}
                          >
                            <MdModeEditOutline
                              className={`${style.edit_schemes_icon}`}
                            />
                          </Link>
                          <Link
                            to=""
                            className="ms-2"
                            onClick={() => handleDelete(ele.id)}
                          >
                            <MdDelete
                              className={`${style.delete_schemes_icon}`}
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <div className="data_not_found">
                      <img
                        src={require("../../component/assets/images/data-not-found.png")}
                        alt="img-not-found"
                        className="img-fluid"
                      />
                    </div>
                  </td>
                </tr>
              )}
              {/* <tr>
                <th scope="row">
                  <div className={style.product_title_on_middle}>1</div>
                </th>
                <td className="d-flex justify-content-center align-items-center">
                  <div className={style.img_width}>
                    <img
                      src={require("../../component/assets/images/img-not-found.webp")}
                      className="img-fluid"
                      alt="schemes"
                    />
                  </div>
                </td>
                <td>
                  <div className={style.product_title_on_middle}>
                    श्री कृष्ण महानुभाव मंदिर
                  </div>
                </td>

                <td>
                  <div className={style.product_title_on_middle}>
                    <button className="view_address">View</button>
                  </div>
                </td>
                <td>
                  <div className={style.product_title_on_middle}>
                    <button
                      className="view_address"
                    >
                      View
                    </button>
                  </div>
                </td>
                <Dialog
                  onClose={handleCloseDialog}
                >
                  <DialogTitle>Address</DialogTitle>
                  <DialogContent>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <td>
                  <div className={style.product_title_on_middle}>
                    <input type="checkbox" />
                  </div>
                </td>
                <td>
                  <div className={style.product_title_on_middle}>
                    <Link
                   
                    >
                      <MdModeEditOutline
                        className={`${style.edit_schemes_icon}`}
                      />
                    </Link>
                    <Link
                      to=""
                      className="ms-2"
                    >
                      <MdDelete className={`${style.delete_schemes_icon}`} />
                    </Link>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default ImportantPlaces;
