import React, { useEffect, useState } from "react";
import style from "../members/members.module.css";
import { Link } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import "../members/member.css";
import "./socialWork.css";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SocialWork = () => {
  const [search, setSearch] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [isUrlDialogOpen, setIsUrlDialogOpen] = useState("");
  const [isYouTubeUrlDialogOpen, setIsYouTubeUrlDialogOpen] = useState("");
  const [isDialogOpen, setDialogOpen] = useState("");
  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleViewAddressClick = (orderId) => {
    setDialogOpen(orderId);
  };

  const handleViewUrlClick = (id) => {
    setIsUrlDialogOpen(id);
  };

  const handleViewYouTubeUrlClick = (id) => {
    setIsYouTubeUrlDialogOpen(id);
  };

  const handleCloseDialog = () => {
    setDialogOpen(null);
    setIsUrlDialogOpen(null);
    setIsYouTubeUrlDialogOpen(null);
  };

  //   list api

  const handleMemberList = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/social_work",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("social work list response", response.data);
      setMemberList(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    handleMemberList();
  }, []);

  const handleRefresh = async () => {
    await handleMemberList();
  };

  // handle delete api

  const handleDelete = async (id) => {
    var check = window.confirm("Are Your Sure To Delete Social Work Item");
    if (!check) {
      return;
    }
    const finalData = {
      social_work_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/delete_social_work",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured deleting data", error);
    }
  };

  const filterCat = memberList.filter((elem) => {
    return elem.title.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className="container h-100">
      <div className="row">
        <div className="col-lg-12">
          <div className="today_order_table">
            <div className="table_body_order">
              <div className="row">
                <div className="col-lg-4">
                  <div className="cart_heading">
                    <h6 className="today_heading">समाजकार्य</h6>
                    <div className="show_entreis">1</div>
                    <h6 className="show_entry_heading">Show entries</h6>
                  </div>
                </div>
                <div className="col-lg-8 d-flex justify-content-end align-items-center gap-3">
                  <div className="bulk_btn">
                    <Link
                      to="/add-social-works"
                      className={`btn ${style.add_new_btn}`}
                    >
                      Add
                    </Link>
                  </div>

                  <div className="today_order_Search me-1">
                    <input
                      type="search"
                      value={search}
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control search_input1"
                    />
                    {!search && <IoIosSearch className="search_icon1" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <table className="table table-hover mt-3">
            <thead>
              <tr className={`${style.main_table_head}`}>
                <th scope="col">ID</th>
                <th scope="col"> Title </th>
                <th scope="col"> Subtitle </th>
                <th scope="col"> Description </th>
                <th scope="col"> Image1 </th>
                <th scope="col"> Image2 </th>
                <th scope="col"> Image3 </th>
                <th scope="col"> Image4 </th>
                <th scope="col"> Image5 </th>
                <th scope="col"> YouTube Url </th>
                {/* <th scope="col">Visible On Home Page</th> */}
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="main_body_table">
              {filterCat?.length > 0 ? (
                filterCat.map((ele, index) => {
                  return (
                    <tr key={ele.id}>
                      <th scope="row">
                        <div className={style.product_title_on_middle}>
                          {index + 1}
                        </div>
                      </th>
                      <td>
                        <div className={style.product_title_on_middle}>
                          {ele.title}
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <button
                            className="view_address"
                            onClick={() => {
                              handleViewUrlClick(ele.id);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <Dialog
                        open={isUrlDialogOpen === ele.id}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>Subtitle</DialogTitle>
                        <DialogContent style={{ paddingBottom: "0px" }}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: ele.sub_title,
                            }}
                          />
                          {/* <p>{ele.map_url}</p> */}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <button
                            className="view_address"
                            onClick={() => {
                              handleViewAddressClick(ele.id);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <Dialog
                        open={isDialogOpen === ele.id}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>Description</DialogTitle>
                        <DialogContent style={{ paddingBottom: "0px" }}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: ele.description,
                            }}
                          />
                          {/* <p>{ele.description}</p> */}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <td className="">
                        <div className="social_work_img">
                          <img
                            src={ele.image1}
                            className="img-fluid"
                            alt="schemes"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="social_work_img">
                          <img
                            src={ele.image2}
                            className="img-fluid"
                            alt="schemes"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="social_work_img">
                          <img
                            src={ele.image3}
                            className="img-fluid"
                            alt="schemes"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="social_work_img">
                          <img
                            src={ele.image4}
                            className="img-fluid"
                            alt="schemes"
                          />
                        </div>
                      </td>
                      <td className="">
                        <div className="social_work_img">
                          <img
                            src={ele.image5}
                            className="img-fluid"
                            alt="schemes"
                          />
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <button
                            className="view_address"
                            onClick={() => {
                              handleViewYouTubeUrlClick(ele.id);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <Dialog
                        open={isYouTubeUrlDialogOpen === ele.id}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>YouTube Url</DialogTitle>
                        <DialogContent style={{ paddingBottom: "0px" }}>
                          <iframe
                            width="500"
                            height="400"
                            // src="https://www.youtube.com/embed/E_gjxdSfGB4?si=2utSOEAWrICF_787"
                            src={ele.youtube_url}
                            title="workers working"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                          {/* <p>{ele.description}</p> */}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {/* <td>
                        <div className={style.product_title_on_middle}>
                          <input
                            type="checkbox"
                            checked={ele.display_on_home === "Y"}
                            onChange={() =>
                              handleVisibleOnDashboard(
                                ele.id,
                                ele.display_on_home === "Y" ? "N" : "Y"
                              )
                            }
                          />
                        </div>
                      </td> */}
                      <td>
                        <div className={style.product_title_on_middle}>
                          {ele.status === "Y" ? (
                            <span className="active_status">अ‍ॅक्टिव</span>
                          ) : (
                            <span className="dective_status">डीअ‍ॅक्टिव</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          <Link to={`/edit-social-works/${ele.id}`}>
                            <MdModeEditOutline
                              className={`${style.edit_schemes_icon}`}
                            />
                          </Link>
                          <Link
                            to=""
                            className="ms-2"
                            onClick={() => handleDelete(ele.id)}
                          >
                            <MdDelete
                              className={`${style.delete_schemes_icon}`}
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={12}>
                    <div className="data_not_found">
                      <img
                        src={require("../../component/assets/images/data-not-found.png")}
                        alt="img-not-found"
                        className="img-fluid"
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default SocialWork;
