import React, { useEffect, useState } from "react";
import "../homeBanner/homeBanner.css";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SchemesBanner = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [bannerData, setBannerData] = useState([]);

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleImageRemove = () => {
    setSelectedImage(null);
    setErrorMessage("");
  };

  //   const handleImageChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onload = function (e) {
  //         setSelectedImage(e.target.result);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          setTimeout(() => {
            if (img.width === 1905 && img.height === 331) {
              console.log("img width", img.width);
              console.log("img height", img.height);
              setSelectedImage(e.target.result);
              setErrorMessage("");
            } else {
              setErrorMessage(`कृपया (1905 x 331) या Size चे Banner टाका
              `);
            }
          }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //   home banner api

  const handleBannerData = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/scheme_banner",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("scheme banner response", response.data);
      setBannerData(response.data.data.scheme_banner);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    handleBannerData();
  }, []);

  // banner add  api

  const handleAddBanner = async (e) => {
    e.preventDefault();
    try {
      if (errorMessage) {
        alert(`कृपया (1905 x 331) या Size चे Banner टाका`);
        return;
      }
      const data = {
        admin_id: userDetails.id,
        image: selectedImage,
      };
      const response = await axios.post(
        API_BASE_URL + "api/update_scheme_banner",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("add scheme response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        const newImage = response.data.data.scheme_banner; // Assuming the API response contains the newly added image data
        setBannerData(newImage); // Update local state with the new image
        setSelectedImage("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occurred in add banner", error);
    }
  };

  return (
    <div className="container h-100">
      <div className="row h-100">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="banner_wrapper">
            <div className="banner_header_top">
              <h4>योजना बॅनर</h4>
            </div>
            <div className="banner_body">
              <form onSubmit={handleAddBanner}>
                <div className="banner_input_group">
                  <label className="input_label">
                    बॅनर निवडा :-{" "}
                    <span>कृपया (1905 x 331) या Size चे Banner टाका</span>
                  </label>
                  {selectedImage ? (
                    <div className="selected_img_group">
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/webp"
                        onChange={handleImageChange}
                        className={`form-control edit_c_cat_input`}
                      />
                      {errorMessage && (
                        <p className="banner_error_text">{errorMessage}</p>
                      )}
                    </>
                  )}
                </div>
                <div className="banner_input_group d-flex justify-content-end mt-4 mb-4">
                  <button className="input_add_btn">बॅनर बदला</button>
                </div>
              </form>
              <ul className="banner_ul_list">
                {bannerData && Object.keys(bannerData).length > 0 ? (
                  <li className="banner_list">
                    <img
                      src={bannerData.banner}
                      alt="banners"
                      className="img-fluid"
                    />
                    {/* <div className="banner_delete_sec">
                    <MdDelete />
                  </div> */}
                  </li>
                ) : (
                  <li className="banner_list">No banner available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default SchemesBanner;
