import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import imgTop from "../assets/images/gram-top-2.png";
import loginMap from "../assets/images/gram_login_left.webp";
import "../login/Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiArrowLongLeft } from "react-icons/hi2";
import { MdLockOpen, MdOutlinePhoneLocked } from "react-icons/md";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { FaEye, FaEyeSlash, FaRegUser } from "react-icons/fa";

const Login = () => {
  const navigate = useNavigate();
  const [loginStart, setLoginStart] = useState(true);
  const [forgatPassPage, setForgatPassPassPage] = useState(false);
  const [goHeadForgatPage, setGoHeadForgatPage] = useState(false);
  const [passwordShow, setPasswordShow] = useState(true);
  const [emailMobile, setEmailMobile] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [checkOtp, setCheckOtp] = useState("");
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("info") != null) {
      console.log("User is already logged in");
      navigate("/dashboard");
    }
  }, []);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Update errors dynamically
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        value.trim() !== ""
          ? ""
          : `${name.charAt(0).toUpperCase() + name.slice(1)} is required *`,
    }));
  };

  const validation = () => {
    let valid = true;
    const newErrors = {
      email: "",
      password: "",
    };

    // User validation
    if (formData.email.trim() === "") {
      newErrors.email = "email is require*";
      valid = false;
    }

    // Password validation
    if (formData.password.trim() === "") {
      newErrors.password = "password is require *";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // const handleRefresh = async () => {
  //   await loginData();
  // };

  //   post method

  const loginData = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    if (validation()) {
      try {
        const finalData = { email: email, password: password };
        // console.log("finalData", finalData);
        const response = await axios.post(
          API_BASE_URL + "api/login",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        // console.log("login response", response.data);
        if (response.data.status === true) {
          sessionStorage.setItem("info", JSON.stringify(response.data.data));
          toast.success(response.data.message);
          navigate("/dashboard");
          // window.location.reload();
          // handleRefresh();
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        console.log(error.response);
      }
    }
  };

  //  send otp
  const handleSendOtp = async (e) => {
    e.preventDefault();
    const finalData = { mobile: emailMobile };
    // console.log("finaldata send otp", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "api/send_otp_forget",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log(response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        // console.log("send otp", response.data);
        setCheckOtp(response.data.data.otp);
        setUserId(response.data.data.role_id);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occured in send otp", error);
    }
  };

  // new password
  const handleNewPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      return toast.error("Please enter the same Password");
    }
    const finalData = { user_id: userId, new_password: newPassword };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/reset_password",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setGoHeadForgatPage(false);
        setForgatPassPassPage(false);
        setLoginStart(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occured create new password", error);
    }
  };

  const handleForgatPassWordPage = () => {
    setLoginStart(false);
    setForgatPassPassPage(true);
  };

  const handleLoginBackPage = () => {
    setLoginStart(true);
    setForgatPassPassPage(false);
    setOtp("");
    setEmailMobile("");
  };

  const handleGoHeadPageForgat = (e) => {
    e.preventDefault();
    if (otp == checkOtp) {
      setForgatPassPassPage(false);
      setGoHeadForgatPage(true);
      toast.success("OTP Verified Successfully");
      setOtp("");
      setEmailMobile("");
    } else {
      toast.error("Please enter correct otp");
    }
  };

  const handleForgatBackPage = () => {
    setForgatPassPassPage(true);
    setGoHeadForgatPage(false);
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>लॉगिन | ग्रामपंचायत</title>
      </Helmet>
      <section className="login_main_section">
        <div className="container-fluid h-100">
          <div className="row  loginMainSection">
            <div className="col-lg-12 col-md-12 col-sm-12 ps-0 pe-0">
              <div className="img-top-se">
                <img
                  src={imgTop}
                  alt="loginTopImage"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
          <div className="row login_left_img_row">
            <div className="col-lg-8 login_left_img_col">
              <div className="login_left_img_map_main">
                <img
                  src={loginMap}
                  alt="loginMap"
                  className="loginMap img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="login_right_main">
                <div className="login_right_logo_top">
                  <img
                    src={require("../../component/assets/images/aapli_gram_logo.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                {loginStart && (
                  <div className="card main-login-card">
                    <div className="card-body login-card">
                      <form onSubmit={(e) => loginData(e)}>
                        <div className="login_profile_logo_mai">
                          <img
                            src={require("../../component/assets/images/login_profile_logo.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="login_right_title_top">
                          <h3 className="user-login-text">
                            ई-ग्रामपंचायत विभाग
                          </h3>
                          <span>ग्रामपंचायत यूजर लॉगिन</span>
                        </div>
                        <div
                          className={` ${
                            errors.user
                              ? "login_pass_error_group"
                              : "login_id_group"
                          }`}
                        >
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="login-input"
                            placeholder="Email"
                          />
                          <div className="login_id_icon_sec">
                            <FaRegUser className="user_id_ico" />
                          </div>
                        </div>
                        <div className="error_sec_login">
                          {errors.email && (
                            <span className="error_text">{errors.email}</span>
                          )}
                        </div>
                        <div
                          className={` ${
                            errors.password
                              ? "login_pass_error_group"
                              : "login_password_group"
                          }`}
                        >
                          <input
                            type={passwordShow ? "password" : "text"}
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="login-input"
                            placeholder="Password"
                            autoComplete="off"
                          />
                          <div className="login_id_icon_sec">
                            <MdLockOpen className="user_pass_ico" />
                          </div>
                          {passwordShow ? (
                            <span onClick={() => setPasswordShow(false)}>
                              <FaEyeSlash className="password_icon" />
                            </span>
                          ) : (
                            <span onClick={() => setPasswordShow(true)}>
                              <FaEye className="password_icon" />
                            </span>
                          )}
                        </div>
                        <div className="error_sec_login">
                          {errors.password && (
                            <span className="error_text">
                              {errors.password}
                            </span>
                          )}
                        </div>
                        <div className="remember_me_sec">
                          <input type="checkbox" />
                          <p className="remember_text">Remember</p>
                        </div>
                        <div className="login_btn_sec">
                          <button type="submit" className="login-btn ">
                            <span>लॉगिन</span>
                          </button>
                        </div>
                        <div className="forgat_pass__btn_sec">
                          <button
                            className="forgat-btn"
                            onClick={handleForgatPassWordPage}
                          >
                            <span> पासवर्ड विसरलात ?</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {/* forgat password page */}
                {forgatPassPage && (
                  <div className="card main-login-card">
                    <div className="page_navigate_top">
                      <HiArrowLongLeft onClick={handleLoginBackPage} />
                    </div>
                    <div className="card-body login-card">
                      <div className="login_profile_logo_mai">
                        <img
                          src={require("../../component/assets/images/login_profile_logo.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                      </div>
                      <div className="login_right_title_top">
                        <h3 className="user-login-text">ई-ग्रामपंचायत विभाग</h3>
                        <span>ग्रामपंचायत यूजर पासवर्ड विसरलात ?</span>
                      </div>
                      <form onSubmit={handleSendOtp}>
                        <div className="user_label_sec">
                          <label htmlFor="userEmail" className="userLabel">
                            Enter email or phone below to reset your password
                          </label>
                        </div>
                        <div
                          className={` ${
                            errors.user
                              ? "login_pass_error_group "
                              : "login_id_group login_otp_group mt-2"
                          }`}
                        >
                          <input
                            type="text"
                            name="emailMobile"
                            className="login-input"
                            placeholder="Email/mobile"
                            value={emailMobile}
                            onChange={(e) => setEmailMobile(e.target.value)}
                            required
                          />
                          <div className="login_id_icon_sec">
                            <FaRegUser className="user_id_ico" />
                          </div>
                        </div>
                        {/* <div className="error_sec_login">
                          {errors.user && (
                            <span className="error_text">{errors.user}</span>
                          )}
                        </div> */}
                        <div className="forgat_pass__btn_sec send_otp_sec">
                          <button className="forgat-btn">
                            <span>सेंड ओटीपी</span>
                          </button>
                        </div>
                      </form>
                      <form onSubmit={handleGoHeadPageForgat}>
                        <div className="login_otp_label_sec">
                          <p className="otp_verification_title">
                            OTP Verification
                          </p>
                          <span className="otp_receive_title">
                            To receive OTP it will take 1 to 2 minutes
                          </span>
                        </div>
                        <div className="opt_inp_sec">
                          <input
                            type="text"
                            name="otp"
                            value={otp}
                            className="otp-input"
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                            required
                            maxLength={4}
                          />
                          <div className="login_otp_icon_sec">
                            <MdOutlinePhoneLocked className="user_otp_ico" />
                          </div>
                        </div>
                        {/* <div className="error_sec_login">
                          {errors.password && (
                            <span className="error_text">
                              {errors.password}
                            </span>
                          )}
                        </div> */}
                        <div className="login_btn_sec">
                          <button type="submit" className="login-btn">
                            <span>पुढे जावा</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {/* forgat go head page */}
                {goHeadForgatPage && (
                  <div className="card main-login-card">
                    <div className="page_navigate_top">
                      <HiArrowLongLeft onClick={handleForgatBackPage} />
                    </div>
                    <div className="card-body login-card">
                      <form onSubmit={handleNewPassword}>
                        <div className="login_profile_logo_mai">
                          <img
                            src={require("../../component/assets/images/login_profile_logo.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="login_right_title_top">
                          <h3 className="user-login-text">
                            ई-ग्रामपंचायत विभाग
                          </h3>
                          <span>नवीन पासवर्ड टाईप करा</span>
                        </div>
                        <div className="login_password_group">
                          <input
                            type={passwordShow ? "password" : "text"}
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="login-input"
                            placeholder="New Password"
                            autoComplete="off"
                            required
                          />
                          <div className="login_id_icon_sec">
                            <MdLockOpen className="user_pass_ico" />
                          </div>
                          {passwordShow ? (
                            <span onClick={() => setPasswordShow(false)}>
                              <FaEyeSlash className="password_icon" />
                            </span>
                          ) : (
                            <span onClick={() => setPasswordShow(true)}>
                              <FaEye className="password_icon" />
                            </span>
                          )}
                        </div>
                        {/* <div className="error_sec_login">
                          {errors.password && (
                            <span className="error_text">
                              {errors.password}
                            </span>
                          )}
                        </div> */}
                        <div className="login_password_group">
                          <input
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="login-input"
                            placeholder="Confirm Password"
                            autoComplete="off"
                            required
                          />
                          <div className="login_id_icon_sec">
                            <MdLockOpen className="user_pass_ico" />
                          </div>
                          {/* {passwordShow ? (
                            <span onClick={() => setPasswordShow(false)}>
                              <FaEyeSlash className="password_icon" />
                            </span>
                          ) : (
                            <span onClick={() => setPasswordShow(true)}>
                              <FaEye className="password_icon" />
                            </span>
                          )} */}
                        </div>
                        {/* <div className="error_sec_login">
                          {errors.password && (
                            <span className="error_text">
                              {errors.password}
                            </span>
                          )}
                        </div> */}
                        {/* <div className="remember_me_sec">
                          <input type="checkbox" />
                          <p className="remember_text">Remember</p>
                        </div> */}
                        <div className="login_btn_sec">
                          <button type="submit" className="login-btn ">
                            <span>जतन करा</span>
                          </button>
                        </div>
                        {/* <div className="forgat_pass__btn_sec">
                          <button
                            className="forgat-btn"
                            onClick={handleForgatPassWordPage}
                          >
                            <span> पासवर्ड विसरलात ?</span>
                          </button>
                        </div> */}
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </React.Fragment>
  );
};

export default Login;
