import React, { useEffect, useState } from "react";
import "../banner/homeBanner/homeBanner.css";
import "./ourGram.css";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const OurGrampanchayat = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImagesec, setSelectedImageSec] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedImageThird, setSelectedImageThird] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [bannerData, setBannerData] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
    setErrorMessage("");
  };
  const handleImageRemove2 = () => {
    setSelectedImage2(null);
    setSelectedImageSec(null);
    setErrorMessage2("");
  };
  const handleImageRemove3 = () => {
    setSelectedImage3(null);
    setSelectedImageThird(null);
    setErrorMessage3("");
  };

  // validation

  const validation = () => {
    let valid = true;
    if (!description) {
      setDescriptionError("Description is required");
      valid = false;
    } else {
      setDescriptionError("");
    }
    return valid;
  };

  //   const handleImageChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onload = function (e) {
  //         setSelectedImage(e.target.result);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   };

  // handleImage1

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          setTimeout(() => {
            if (img.width === 614 && img.height === 520) {
              console.log("img width", img.width);
              console.log("img height", img.height);
              setSelectedImage(e.target.result);
              setErrorMessage("");
            } else {
              setErrorMessage(`कृपया (614 x 520) या Size चे Banner टाका
              `);
            }
          }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // handleImage2

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          setTimeout(() => {
            if (img.width === 315 && img.height === 253) {
              console.log("img width", img.width);
              console.log("img height", img.height);
              setSelectedImage2(e.target.result);
              setErrorMessage2("");
            } else {
              setErrorMessage2(`कृपया (315 x 253) या Size चे Banner टाका
              `);
            }
          }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // handleImage3

  const handleImageChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          setTimeout(() => {
            if (img.width === 315 && img.height === 253) {
              console.log("img width", img.width);
              console.log("img height", img.height);
              setSelectedImage3(e.target.result);
              setErrorMessage3("");
            } else {
              setErrorMessage3(`कृपया (315 x 253) या Size चे Banner टाका
              `);
            }
          }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //   home banner api

  const handleBannerData = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/our_village_about",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("our villages banner response", response.data);
      setBannerData(response.data.data);
      if (response.data.data.image1) {
        setSelectedImage1(response.data.data.image1);
      }
      // setSelectedImage(response.data.data.image1);
      if (response.data.data.image2) {
        setSelectedImageSec(response.data.data.image2);
      }
      if (response.data.data.image3) {
        setSelectedImageThird(response.data.data.image3);
      }
      // setSelectedImage2(response.data.data.image2);
      setDescription(response.data.data.description);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    handleBannerData();
  }, []);

  const handleRefresh = async () => {
    await handleBannerData();
  };

  // banner add  api

  const handleAddBanner = async (e) => {
    e.preventDefault();
    if (validation()) {
      if (errorMessage || errorMessage2 || errorMessage3) {
        alert(errorMessage || errorMessage2 || errorMessage3);
        return;
      } else {
        try {
          const data = {
            admin_id: userDetails.id,
            // image1: selectedImage,
            // image2: selectedImage2,
            // image3: selectedImage3,
            description: description,
          };
          if (selectedImage) {
            data.image1 = selectedImage;
          }
          if (selectedImage2) {
            data.image2 = selectedImage2;
          }
          if (selectedImage3) {
            data.image3 = selectedImage3;
          }
          console.log("add our village finalData", data);

          const response = await axios.post(
            API_BASE_URL + "api/update_our_village_about",
            data,
            {
              auth: {
                username: API_AUTH_USERNAME,
                password: API_AUTH_PASSWORD,
              },
            }
          );
          console.log("add our village response", response.data);
          if (response.data.status === true) {
            toast.success(response.data.message);
            const newImage = response.data.data;
            setBannerData(newImage);
            setSelectedImage("");
            setSelectedImage2("");
            setSelectedImage3("");
            setDescription("");
            handleRefresh();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.log("error occurred in add banner", error);
        }
      }
    }
  };

  return (
    <div className="container">
      <div className="row h-100">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="banner_wrapper">
            <div className="banner_header_top">
              <h4>आपली ग्रामपंचायत</h4>
            </div>
            <div className="banner_body">
              <form onSubmit={handleAddBanner}>
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="banner_input_group">
                      <label className="input_label">
                        पहिली इमेज :-{" "}
                        <span>कृपया (614 x 520) या Size चे Banner टाका</span>
                      </label>
                      {selectedImage1 && !selectedImage && (
                        <div className="selected_img_group">
                          <img
                            src={selectedImage1}
                            alt="Selected"
                            className="img-fluid"
                          />
                          <button
                            onClick={handleImageRemove}
                            className={`btn remove_banner_btn`}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                      {selectedImage && !selectedImage1 && (
                        <div className="selected_img_group">
                          <img
                            src={selectedImage}
                            alt="Selected"
                            className="img-fluid"
                          />
                          <button
                            onClick={handleImageRemove}
                            className={`btn remove_banner_btn`}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                      {!selectedImage1 && !selectedImage && (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className={`form-control common_input`}
                          />
                          {errorMessage && (
                            <p className="banner_error_text">{errorMessage}</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner_input_group">
                      <label className="input_label">दुसरी इमेज :- </label>
                      {selectedImagesec && !selectedImage2 && (
                        <div className="selected_img_group">
                          <img
                            src={selectedImagesec}
                            alt="Selected"
                            className="img-fluid"
                          />
                          <button
                            onClick={handleImageRemove2}
                            className={`btn remove_banner_btn`}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                      {selectedImage2 && !selectedImagesec && (
                        <div className="selected_img_group">
                          <img
                            src={selectedImage2}
                            alt="Selected"
                            className="img-fluid"
                          />
                          <button
                            onClick={handleImageRemove2}
                            className={`btn remove_banner_btn`}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                      {!selectedImagesec && !selectedImage2 && (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange2}
                            className={`form-control common_input`}
                          />
                          {errorMessage2 && (
                            <p className="banner_error_text">{errorMessage2}</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner_input_group">
                      <label className="input_label">तिसरी इमेज :- </label>
                      {/* {selectedImage3 ? (
                        <div className="selected_img_group">
                          <img
                            src={selectedImage3}
                            alt="Selected"
                            className="img-fluid"
                          />
                          <button
                            onClick={handleImageRemove3}
                            className={`btn remove_banner_btn`}
                          >
                            Remove
                          </button>
                        </div>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept="image/jpeg, image/png, image/webp"
                            onChange={handleImageChange3}
                            className={`form-control edit_c_cat_input`}
                          />
                          {errorMessage3 && (
                            <p className="banner_error_text">{errorMessage3}</p>
                          )}
                        </>
                      )} */}
                      {selectedImageThird && !selectedImage3 && (
                        <div className="selected_img_group">
                          <img
                            src={selectedImageThird}
                            alt="Selected"
                            className="img-fluid"
                          />
                          <button
                            onClick={handleImageRemove3}
                            className={`btn remove_banner_btn`}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                      {selectedImage3 && !selectedImageThird && (
                        <div className="selected_img_group">
                          <img
                            src={selectedImage3}
                            alt="Selected"
                            className="img-fluid"
                          />
                          <button
                            onClick={handleImageRemove3}
                            className={`btn remove_banner_btn`}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                      {!selectedImageThird && !selectedImage3 && (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange3}
                            className={`form-control common_input`}
                          />
                          {errorMessage3 && (
                            <p className="banner_error_text">{errorMessage3}</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-2 mb-2">
                    <span className="error_note_banner">
                      कृपया दुसरी आणि तिसरी Image (315 x 253) या Size चे Images
                      टाका
                    </span>
                  </div>
                  <div className="col-lg-12 mt-2">
                    <div className="banner_input_group">
                      <label className="input_label">वर्णन :- </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onChange={handleProductDescription}
                      />
                      {descriptionError && (
                        <span className="banner_error_text">
                          {descriptionError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="banner_input_group d-flex justify-content-end mt-4 mb-4">
                  <button className="input_add_btn">बॅनर बदला</button>
                </div>
              </form>
              <ul className="banner_ul_list">
                {bannerData && Object.keys(bannerData).length > 0 ? (
                  <li className="banner_list">
                    <div className="large_img_our_village_sec">
                      <h6 className="our_village_title">पहिली इमेज :-</h6>
                      <img
                        src={bannerData.image1}
                        alt="banners"
                        className="img-fluid"
                      />
                    </div>
                    <div className="small_img_our_village_sec">
                      <h6 className="our_village_title">
                        दुसरी आणि तिसरी इमेज :-
                      </h6>
                      <div className="sm_img_sec">
                        <img
                          src={bannerData.image2}
                          alt="banners"
                          className="img-fluid"
                        />
                        <img
                          src={bannerData.image3}
                          alt="banners"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="description_contact_sec">
                      <h6 className="our_village_title">वर्णन :-</h6>
                      <p
                        className="our_village_para"
                        dangerouslySetInnerHTML={{
                          __html: bannerData.description,
                        }}
                      />
                    </div>
                  </li>
                ) : (
                  <li className="banner_list">कोणतेही बॅनर नाही</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default OurGrampanchayat;
