import React, { useEffect, useState } from "react";
import style from "../members/members.module.css";
import { Link } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import "../members/member.css";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PhotoVideoList = () => {
  const [search, setSearch] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [displayOnHome, setDisplayOnHome] = useState("Y");
  const [isYouTubeUrlDialogOpen, setIsYouTubeUrlDialogOpen] = useState("");
  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleDisplayOnHome = (e) => {
    setDisplayOnHome(e.target.value);
  };

  const handleViewYouTubeUrlClick = (id) => {
    setIsYouTubeUrlDialogOpen(id);
  };

  const handleCloseDialog = () => {
    setIsYouTubeUrlDialogOpen(null);
  };

  //   home banner api

  const handleMemberList = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/photo_video",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("photo/video list response", response.data);
      setMemberList(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    handleMemberList();
  }, []);

  const handleRefresh = async () => {
    await handleMemberList();
  };

  // Scheme Set Visible on dashboard

  const handleVisibleOnDashboard = async (member_id, display_on_home) => {
    try {
      const finalData = {
        member_id: member_id,
        display_on_home: display_on_home,
      };
      console.log("member Set Visible on dashboard finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/member_visible_on_home",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("member Set Visible on dashboard", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
      }
      handleRefresh();
      // setSchemeList(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  // handle delete scheme

  const handleDelete = async (id) => {
    var check = window.confirm("Are Your Sure To Delete Member Item");
    if (!check) {
      return;
    }
    const finalData = {
      photo_video_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/delete_photo_video",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured deleting data", error);
    }
  };

  return (
    <div className="container h-100">
      <div className="row">
        <div className="col-lg-12">
          <div className="today_order_table">
            <div className="table_body_order">
              <div className="row">
                <div className="col-lg-4">
                  <div className="cart_heading">
                    <h6 className="today_heading">सर्व फोटो/व्हिडीओ</h6>
                    <div className="show_entreis">{memberList?.length}</div>
                    <h6 className="show_entry_heading">Show entries</h6>
                  </div>
                </div>
                <div className="col-lg-8 d-flex justify-content-end align-items-center gap-3">
                  <div className="bulk_btn ">
                    <Link
                      to="/add-images"
                      className={`btn ${style.add_new_btn}`}
                    >
                      अ‍ॅड इमेज
                    </Link>
                  </div>
                  <div className="bulk_btn me-4">
                    <Link
                      to="/add-video"
                      className={`btn ${style.add_new_btn}`}
                    >
                      अ‍ॅड यूट्यूब Url
                    </Link>
                  </div>

                  {/* <div className="today_order_Search me-1">
                    <input
                      type="search"
                      value={search}
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control search_input1"
                    />
                    {!search && <IoIosSearch className="search_icon1" />}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <table className="table table-hover mt-3">
            <thead>
              <tr className={`${style.main_table_head}`}>
                <th scope="col">अ.क्रं</th>
                <th scope="col"> इमेज / यूट्यूब Url</th>
                {/* <th scope="col"> यूट्यूब Url </th> */}
                {/* <th scope="col"> Role Name </th>
                <th scope="col"> Number </th> */}
                {/* <th scope="col">Visible On Home Page</th> */}
                <th scope="col">स्टेटस</th>
                <th scope="col">क्रिया</th>
              </tr>
            </thead>
            <tbody className="main_body_table">
              {memberList.length > 0 ? (
                memberList.map((ele, index) => {
                  return (
                    <tr key={ele.id}>
                      <th scope="row">
                        <div className={style.product_title_on_middle}>
                          {index + 1}
                        </div>
                      </th>
                      <td className="d-flex justify-content-center align-items-center">
                        <div className={style.img_width}>
                          {ele.type === "image" ? (
                            <img
                              src={ele.image}
                              className="img-fluid"
                              alt="schemes"
                            />
                          ) : (
                            <div className={style.product_title_on_middle}>
                              <button
                                className="view_address"
                                onClick={() => {
                                  handleViewYouTubeUrlClick(ele.id);
                                }}
                              >
                                View
                              </button>
                            </div>
                          )}
                          {/* <img
                            src={ele.image}
                            className="img-fluid"
                            alt="schemes"
                          /> */}
                        </div>
                      </td>
                      {/* <td>
                        <div className={style.product_title_on_middle}>
                          <button
                            className="view_address"
                            onClick={() => {
                              handleViewYouTubeUrlClick(ele.id);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td> */}
                      <Dialog
                        open={isYouTubeUrlDialogOpen === ele.id}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>YouTube Url</DialogTitle>
                        <DialogContent style={{ paddingBottom: "0px" }}>
                          <iframe
                            width="500"
                            height="400"
                            // src="https://www.youtube.com/embed/E_gjxdSfGB4?si=2utSOEAWrICF_787"
                            src={ele.youtube_url}
                            title="workers working"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                          {/* <p>{ele.description}</p> */}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {/* <td>
                        <div className={style.product_title_on_middle}>
                          {ele.role_name}
                        </div>
                      </td>
                      <td>
                        <div className={style.product_title_on_middle}>
                          9989794789 | 098579958
                        </div>
                      </td> */}
                      {/* <td>
                        <div className={style.product_title_on_middle}>
                          <input
                            type="checkbox"
                            checked={ele.display_on_home === "Y"}
                            onChange={() =>
                              handleVisibleOnDashboard(
                                ele.id,
                                ele.display_on_home === "Y" ? "N" : "Y"
                              )
                            }
                          />
                        </div>
                      </td> */}
                      <td>
                        <div className={style.product_title_on_middle}>
                          {ele.status === "Y" ? (
                            <span className="active_status">अ‍ॅक्टिव</span>
                          ) : (
                            <span className="dective_status">डीअ‍ॅक्टिव</span>
                          )}
                        </div>
                      </td>
                      <td>
                        {ele.type === "image" ? (
                          <div className={style.product_title_on_middle}>
                            <Link to={`/edit-images/${ele.id}`}>
                              <MdModeEditOutline
                                className={`${style.edit_schemes_icon}`}
                              />
                            </Link>
                            <Link
                              to=""
                              className="ms-2"
                              onClick={() => handleDelete(ele.id)}
                            >
                              <MdDelete
                                className={`${style.delete_schemes_icon}`}
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className={style.product_title_on_middle}>
                            <Link to={`/edit-video/${ele.id}`}>
                              <MdModeEditOutline
                                className={`${style.edit_schemes_icon}`}
                              />
                            </Link>
                            <Link
                              to=""
                              className="ms-2"
                              onClick={() => handleDelete(ele.id)}
                            >
                              <MdDelete
                                className={`${style.delete_schemes_icon}`}
                              />
                            </Link>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <div className="data_not_found">
                      <img
                        src={require("../../component/assets/images/data-not-found.png")}
                        alt="img-not-found"
                        className="img-fluid"
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default PhotoVideoList;
