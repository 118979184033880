import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./component/dashboard/Dashboard";
import Login from "./component/login/Login";
import AdminLogin from "./component/login/AdminLogin";

import MyBeShowNavbar from "./component/MyBeShowNavbar/MyBeShowNavbar";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Profile from "./component/Profile/Profile";

import Navbar from "./component/navbar/Navbar";
import Footer from "./component/footer/Footer";

import React from "react";
import Schemes from "./pages/schemes/Schemes";
import AddSchemes from "./pages/schemes/AddSchemes";
import HomeBanner from "./pages/banner/homeBanner/HomeBanner";
import EditSchemes from "./pages/schemes/EditSchemes";
import Members from "./pages/members/Members";
import AddMembers from "./pages/members/AddMembers";
import EditMember from "./pages/members/EditMember";
import ImportantPlaces from "./pages/importantPlaces/ImportantPlaces";
import AddImpPlace from "./pages/importantPlaces/AddImpPlace";
import EditImpPlace from "./pages/importantPlaces/EditImpPlace";
import OurGrampanchayat from "./pages/ourGrampanchayat/OurGrampanchayat";
import SchemesBanner from "./pages/banner/schemesBanner/SchemesBanner";
import Excel from "./pages/exceFile/Excel";
import PublicFacilities from "./pages/publicFacilities/PublicFacilities";
import AddPublicFacilities from "./pages/publicFacilities/AddPublicFacilities";
import EditPublicFacilities from "./pages/publicFacilities/EditPublicFacilities";
import Contact from "./pages/contact/Contact";
import EmergencyNumbers from "./pages/emergencyNumbers/EmergencyNumbers";
import AddEmergencyContact from "./pages/emergencyNumbers/AddEmergencyContact";
import EditEmergencyContact from "./pages/emergencyNumbers/EditEmergencyContact";
import PartnerLogos from "./pages/partnerLogos/PartnerLogos";
import AddPartnerLogos from "./pages/partnerLogos/AddPartnerLogos";
import EditPartnerLogos from "./pages/partnerLogos/EditPartnerLogos";
import SocialAwards from "./pages/socialAwards/SocialAwards";
import AddSocialAwards from "./pages/socialAwards/AddSocialAwards";
import EditSocialAwards from "./pages/socialAwards/EditSocialAwards";
import SocialWork from "./pages/socialWork/SocialWork";
import AddSocialWork from "./pages/socialWork/AddSocialWork";
import EditSocialWork from "./pages/socialWork/EditSocialWork";
import VillageSummary from "./pages/villageSummary/VillageSummary";
import AddVillageSummary from "./pages/villageSummary/AddVillageSummary";
import EditVillageSummary from "./pages/villageSummary/EditVillageSummary";
import PhotoVideoList from "./pages/photosVideo/PhotoVideoList";
import AddImages from "./pages/photosVideo/images/AddImages";
import EditImages from "./pages/photosVideo/images/EditImages";
import AddVideo from "./pages/photosVideo/video/AddVideo";
import EditVideo from "./pages/photosVideo/video/EditVideo";
import LatestNewsList from "./pages/latestNews/LatestNewsList";
import AddLatestNew from "./pages/latestNews/latestNewsSec/AddLatestNew";
import EditLatestNew from "./pages/latestNews/latestNewsSec/EditLatestNew";
import AddWhatIsNew from "./pages/latestNews/whatIsNew/AddWhatIsNew";
import EditWhatIsNew from "./pages/latestNews/whatIsNew/EditWhatIsNew";
import PurposeList from "./pages/about/purpose/PurposeList";
import AddPurpose from "./pages/about/purpose/AddPurpose";
import EditPurpose from "./pages/about/purpose/EditPurpose";
import OurGoal from "./pages/about/ourGoal/OurGoal";
import WhatIsNewList from "./pages/latestNews/WhatIsNewList";
import MobileHomeBanner from "./pages/banner/mobileHomeBanner/MobileHomeBanner";
import MobileSchemesBanner from "./pages/banner/mobileSchemesBanner/MobileSchemesBanner";
import SocialLinks from "./pages/socialLinks/SocialLinks";

function App() {
  var userDataInfo = sessionStorage.getItem("info");
  var userDetails = JSON.parse(userDataInfo);
  var userInfo = userDetails ? userDetails.user_details : "";
  const location = useLocation("");

  const footerHide = location.pathname === "/dashboard";

  return (
    <HelmetProvider>
      <MyBeShowNavbar>
        <Navbar />
      </MyBeShowNavbar>
      <Routes>
        <Route path="/*" element={<PageNotFound />} />
        {/* {userDetails && <Route path="/dashboard" element={<Dashboard />} />} */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/admin-login" element={<AdminLogin />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/schemes" element={<Schemes />} />
        <Route path="/add_schemes" element={<AddSchemes />} />
        <Route path="/edit_schemes/:id" element={<EditSchemes />} />
        {/* banners  */}
        <Route path="/home_banner" element={<HomeBanner />} />
        <Route path="/mobile_home_banner" element={<MobileHomeBanner />} />
        <Route path="/schemes_banner" element={<SchemesBanner />} />
        <Route
          path="/mobile_schemes_banner"
          element={<MobileSchemesBanner />}
        />
        {/* members */}
        <Route path="/members" element={<Members />} />
        <Route path="/add-members" element={<AddMembers />} />
        <Route path="/edit-members/:id" element={<EditMember />} />
        {/* important places */}
        <Route path="/imp-places" element={<ImportantPlaces />} />
        <Route path="/add-imp-places" element={<AddImpPlace />} />
        <Route path="/edit-imp-places/:id" element={<EditImpPlace />} />
        {/* our village Grampanchayat */}
        <Route path="/our-grampanchayat" element={<OurGrampanchayat />} />
        {/* <Route path="/excel" element={<Excel />} /> */}

        {/* public facilities */}
        <Route path="/public-facilities" element={<PublicFacilities />} />
        <Route path="/add-facilities" element={<AddPublicFacilities />} />
        <Route path="/edit-facilities/:id" element={<EditPublicFacilities />} />
        {/* contact */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/social-links" element={<SocialLinks />} />

        {/* emergency contact */}
        <Route path="/emergency-contact" element={<EmergencyNumbers />} />
        <Route
          path="/add-emergency-contact"
          element={<AddEmergencyContact />}
        />
        <Route
          path="/edit-emergency-contact/:id"
          element={<EditEmergencyContact />}
        />
        {/* partners logo */}
        <Route path="/our-partners" element={<PartnerLogos />} />
        <Route path="/add-partners" element={<AddPartnerLogos />} />
        <Route path="/edit-partners/:id" element={<EditPartnerLogos />} />

        {/* social Awards logo */}

        <Route path="/social-awards" element={<SocialAwards />} />
        <Route path="/add-awards" element={<AddSocialAwards />} />
        <Route path="/edit-awards/:id" element={<EditSocialAwards />} />

        {/* social works */}

        <Route path="/social-works" element={<SocialWork />} />
        <Route path="/add-social-works" element={<AddSocialWork />} />
        <Route path="/edit-social-works/:id" element={<EditSocialWork />} />

        {/* village summary */}
        <Route path="/village-summary" element={<VillageSummary />} />
        <Route path="/add-village-summary" element={<AddVillageSummary />} />
        <Route
          path="/edit-village-summary/:id"
          element={<EditVillageSummary />}
        />

        {/* photos and video */}

        <Route path="/photo-video-list" element={<PhotoVideoList />} />
        <Route path="/add-images" element={<AddImages />} />
        <Route path="/edit-images/:id" element={<EditImages />} />
        <Route path="/add-video" element={<AddVideo />} />
        <Route path="/edit-video/:id" element={<EditVideo />} />

        {/* latest news */}
        <Route path="/latest-news-list" element={<LatestNewsList />} />
        <Route path="/what-is-new" element={<WhatIsNewList />} />
        <Route path="/add-latest-news" element={<AddLatestNew />} />
        <Route path="/edit-latest-news/:id" element={<EditLatestNew />} />
        <Route path="/add-what-is-new" element={<AddWhatIsNew />} />
        <Route path="/edit-what-is-new/:id" element={<EditWhatIsNew />} />

        {/* about page */}
        <Route path="/purpose-list" element={<PurposeList />} />
        <Route path="/add-purpose" element={<AddPurpose />} />
        <Route path="/edit-purpose/:id" element={<EditPurpose />} />
        <Route path="/our-goal" element={<OurGoal />} />
      </Routes>

      {!footerHide && <Footer />}
    </HelmetProvider>
  );
}

export default App;
