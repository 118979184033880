import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import imgTop from "../assets/images/gram-top-2.png";
import loginMap from "../assets/images/gram_login_left.webp";
import "../login/Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdLockOpen } from "react-icons/md";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdOutlineCheck } from "react-icons/md";
import { GoPasskeyFill } from "react-icons/go";
const Profile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState("");
  const [passwordShow, setPasswordShow] = useState(true);
  const [passwordShowNew, setPasswordShowNew] = useState(true);
  const [formData, setFormData] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  // get user id  for session to get data in profile
  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  // console.log("userDetails", userDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Update errors dynamically
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        value.trim() !== ""
          ? ""
          : `${name.charAt(0).toUpperCase() + name.slice(1)} is required *`,
    }));
  };

  const validation = () => {
    let valid = true;
    const newErrors = {
      old_password: "",
      password: "",
      confirm_password: "",
    };

    // User validation
    if (formData.old_password.trim() === "") {
      newErrors.old_password = "Old Password is require*";
      valid = false;
    }

    if (!formData.password.trim()) {
      newErrors.password = "New Password is require*";
      valid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
      valid = false;
    }

    if (!formData.confirm_password.trim()) {
      newErrors.confirm_password = "Confirm password is required";
      valid = false;
    } else if (formData.password !== formData.confirm_password) {
      newErrors.confirm_password = "Passwords do not match";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // const handleRefresh = async () => {
  //   await loginData();
  // };

  //   post method

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const { old_password, password } = formData;

    if (validation()) {
      try {
        const finalData = {
          user_id: userDetails.id,
          old_password: old_password,
          new_password: password,
        };
        // console.log("update password finalData", finalData);
        const response = await axios.post(
          API_BASE_URL + "api/change_password",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        // console.log("password update response", response.data);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setFormData({
            old_password: "",
            password: "",
            confirm_password: "",
          });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.data);
          console.log(error.response.status);
        }
      }
    }
  };

  // profile Data
  useEffect(() => {
    const handleProfileData = async () => {
      const finalData = { user_id: userDetails.id };
      try {
        const response = await axios.post(
          API_BASE_URL + "api/user_details_by_id",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        // console.log("profile Data", response.data);
        setProfileData(response.data.data);
      } catch (error) {
        console.log("showing error fetching data", error);
      }
    };
    handleProfileData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>यूजर तपशील | ग्रामपंचायत</title>
      </Helmet>
      <section className="profile_main_sec">
        <div className="container-fluid h-100">
          <div className="row login_left_img_row">
            <div className="col-lg-12">
              <div className="login_right_main">
                <div className="login_right_logo_top">
                  <img
                    src={require("../../component/assets/images/aapli_gram_logo.png")}
                    alt="logo"
                    className="img-fluid grampanchyat_top"
                  />
                </div>
                <div className="card main-login-card mb-5">
                  <div className="card-body login-card">
                    <div className="login_profile_logo_mai">
                      <img
                        src={require("../../component/assets/images/login_profile_logo.png")}
                        alt="logo"
                        className="img-fluid img_profile"
                      />
                    </div>
                    <div className="login_right_title_top">
                      <h3 className="profile_user-login-text">
                        {profileData?.user_name}
                      </h3>
                      <span className="profileDetails_span">
                        {profileData?.user_email}
                      </span>
                    </div>
                    <div className="main_profile_details">
                      <div className="main_profile_box">
                        <div
                          className={` ${"login_id_group profile_detail_box"}`}
                        >
                          <div className="label-container">
                            <label
                              className="name_profile profile_label_name"
                              htmlFor=""
                            >
                              Name
                            </label>
                          </div>
                          <div>
                            <label className="profile_label_name" htmlFor="">
                              {profileData?.user_name}
                            </label>
                          </div>
                        </div>
                        <div
                          className={` ${"login_id_group profile_detail_box"}`}
                        >
                          <div className="label-container">
                            <label
                              className="name_profile profile_label_name"
                              htmlFor=""
                            >
                              Role
                            </label>
                          </div>
                          <div>
                            <label className="profile_label_name" htmlFor="">
                              {profileData?.role_name}
                            </label>
                          </div>
                        </div>
                        <div
                          className={` ${"login_id_group profile_detail_box"}`}
                        >
                          <div className="label-container">
                            <label
                              className="name_profile profile_label_name"
                              htmlFor=""
                            >
                              Email
                            </label>
                          </div>
                          <div>
                            <label className="profile_label_name" htmlFor="">
                              {profileData?.user_email}
                            </label>
                          </div>
                        </div>
                        <div
                          className={` ${"login_id_group profile_detail_box"}`}
                        >
                          <div className="label-container">
                            <label
                              className="name_profile profile_label_name"
                              htmlFor=""
                            >
                              Contact
                            </label>
                          </div>
                          <div>
                            <label className="profile_label_name" htmlFor="">
                              {profileData?.user_mobile}
                            </label>
                          </div>
                        </div>
                        {profileData?.district_name && (
                          <div
                            className={` ${"login_id_group profile_detail_box"}`}
                          >
                            <div className="label-container">
                              <label
                                className="name_profile profile_label_name"
                                htmlFor=""
                              >
                                District
                              </label>
                            </div>
                            <div>
                              <label className="profile_label_name" htmlFor="">
                                {profileData?.district_name}
                              </label>
                            </div>
                          </div>
                        )}
                        <div
                          className={` ${"login_id_group profile_detail_box"}`}
                        >
                          <div className="label-container">
                            <label
                              className="name_profile profile_label_name"
                              htmlFor=""
                            >
                              Taluka
                            </label>
                          </div>
                          {profileData?.talukas &&
                            Array.isArray(profileData?.talukas) &&
                            profileData?.talukas.map((item) => (
                              <div key={item.taluka_id}>
                                <label
                                  className="profile_label_name"
                                  htmlFor=""
                                >
                                  {item.taluka_name}
                                </label>
                              </div>
                            ))}
                        </div>
                        <div
                          className={` ${"login_id_group profile_detail_box"}`}
                        >
                          <div className="label-container">
                            <label
                              className="name_profile profile_label_name"
                              htmlFor=""
                            >
                              Village
                            </label>
                          </div>
                          {profileData?.villages &&
                            Array.isArray(profileData?.villages) &&
                            profileData?.villages.map((item) => {
                              return (
                                <div key={item.village_id}>
                                  <label
                                    className="profile_label_name"
                                    htmlFor=""
                                  >
                                    {item.village_name}
                                  </label>
                                </div>
                              );
                            })}
                        </div>

                        {/* change password  */}
                        <form onSubmit={(e) => handleUpdatePassword(e)}>
                          <div>
                            <h3 className="profile_user-login-text">
                              Change Password
                            </h3>
                            <div
                              className={` ${
                                errors.old_password
                                  ? "login_pass_error_group_change_password"
                                  : "login_password_group"
                              }`}
                            >
                              <input
                                type={passwordShow ? "password" : "text"}
                                name="old_password"
                                value={formData.old_password}
                                onChange={handleChange}
                                className="login-input profile_details_placeholder"
                                placeholder="Old Password"
                                autoComplete="off"
                              />
                              <div className="login_id_icon_sec">
                                <MdLockOpen className="user_pass_ico" />
                              </div>
                              {passwordShow ? (
                                <span onClick={() => setPasswordShow(false)}>
                                  <FaEyeSlash className="password_icon" />
                                </span>
                              ) : (
                                <span onClick={() => setPasswordShow(true)}>
                                  <FaEye className="password_icon" />
                                </span>
                              )}
                            </div>
                            <div className="error_sec_login">
                              {errors.old_password && (
                                <span className="error_text">
                                  {errors.old_password}
                                </span>
                              )}
                            </div>
                            <div
                              className={` ${
                                errors.password
                                  ? "login_pass_error_group_change_password"
                                  : "login_password_group"
                              }`}
                            >
                              <input
                                type={passwordShowNew ? "password" : "text"}
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="login-input profile_details_placeholder"
                                placeholder="New Password"
                                autoComplete="off"
                              />
                              <div className="login_id_icon_sec">
                                <GoPasskeyFill className="user_id_ico" />
                              </div>
                              {passwordShowNew ? (
                                <span onClick={() => setPasswordShowNew(false)}>
                                  <FaEyeSlash className="password_icon" />
                                </span>
                              ) : (
                                <span onClick={() => setPasswordShowNew(true)}>
                                  <FaEye className="password_icon" />
                                </span>
                              )}
                            </div>
                            <div className="error_sec_login">
                              {errors.password && (
                                <span className="error_text">
                                  {errors.password}
                                </span>
                              )}
                            </div>
                            <div
                              className={` ${
                                errors.confirm_password
                                  ? "login_pass_error_group_change_password"
                                  : "login_id_group"
                              }`}
                            >
                              <input
                                type="password"
                                name="confirm_password"
                                value={formData.confirm_password}
                                onChange={handleChange}
                                className="login-input profile_details_placeholder"
                                placeholder="Confirm Password"
                                autoComplete="off"
                              />
                              <div className="login_id_icon_sec">
                                <MdOutlineCheck className="user_id_ico" />
                              </div>
                            </div>
                            <div className="error_sec_login">
                              {errors.confirm_password && (
                                <span className="error_text">
                                  {errors.confirm_password}
                                </span>
                              )}
                            </div>
                            <div className="login_btn_sec">
                              <button type="submit" className="login-btn ">
                                <span>पासवर्ड बदला</span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </React.Fragment>
  );
};

export default Profile;
