import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditSocialWork = () => {
  const { id } = useParams("");
  const navigate = useNavigate("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleError, setRoleError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [imageSecond, setImageSecond] = useState(null);
  const [imageSecond1, setImageSecond1] = useState(null);
  const [imageThree, setImageThree] = useState(null);
  const [imageThree1, setImageThree1] = useState(null);
  const [imageFour, setImageFour] = useState(null);
  const [imageFour1, setImageFour1] = useState(null);
  const [imageFive, setImageFive] = useState(null);
  const [imageFive1, setImageFive1] = useState(null);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [displayOnHome, setDisplayOnHome] = useState("Y");
  const [errorImageOne, setErrorImageOne] = useState("");
  const [errorImageSecond, setErrorImageSecond] = useState("");
  const [errorImageThird, setErrorImageThird] = useState("");
  const [errorImageFourth, setErrorImageFourth] = useState("");
  const [errorImageFifth, setErrorImageFifth] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [ytUrlError, setYtUrlError] = useState("");
  const [status, setStatus] = useState("Y");

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleDisplayOnHome = (e) => {
    setDisplayOnHome(e.target.value);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
    setErrorImageOne("");
  };

  const handleImageRemove2 = () => {
    setImageSecond(null);
    setImageSecond1(null);
    setErrorImageSecond("");
  };

  const handleImageRemove3 = () => {
    setImageThree(null);
    setImageThree1(null);
    setErrorImageThird("");
  };
  const handleImageRemove4 = () => {
    setImageFour(null);
    setImageFour1(null);
    setErrorImageFourth("");
  };

  const handleImageRemove5 = () => {
    setImageFive(null);
    setImageFive1(null);
    setErrorImageFifth("");
  };

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  //   image1 handleChange

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          console.log("img1 width", img.width);
          console.log("img1 height", img.height);
          setSelectedImage(e.target.result);
          // setTimeout(() => {
          //   if (img.width === 325 && img.height === 241) {
          //     setSelectedImage(e.target.result);
          //     setErrorImageOne("");
          //   } else {
          //     setErrorImageOne(`कृपया (325 x 241) या Size चे Images टाका`);
          //     setTimeout(() => {
          //       alert(
          //         `तुमी select केलेली height ${img.height} आणि width ${img.width}`
          //       );
          //     }, 2000);
          //   }
          // }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //   image2 handleChange

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          console.log("img2 width", img.width);
          console.log("img2 height", img.height);
          setImageSecond(e.target.result);
          // setTimeout(() => {
          //   if (img.width === 326 && img.height === 241) {
          //     setImageSecond(e.target.result);
          //     setErrorImageSecond("");
          //   } else {
          //     setErrorImageSecond(`कृपया (326 x 241) या Size चे Images टाका`);
          //     setTimeout(() => {
          //       alert(
          //         `तुमी select केलेली height ${img.height} आणि width ${img.width}`
          //       );
          //     }, 2000);
          //   }
          // }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //   image3 handleChange

  const handleImageChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          console.log("img3 width", img.width);
          console.log("img3 height", img.height);
          setImageThree(e.target.result);
          // setTimeout(() => {
          //   if (img.width === 325 && img.height === 241) {
          //     setImageThree(e.target.result);
          //     setErrorImageThird("");
          //   } else {
          //     setErrorImageThird(`कृपया (325 x 241) या Size चे Images टाका`);
          //     setTimeout(() => {
          //       alert(
          //         `तुमी select केलेली height ${img.height} आणि width ${img.width}`
          //       );
          //     }, 2000);
          //   }
          // }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //   image4 handleChange

  const handleImageChange4 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          console.log("img4 width", img.width);
          console.log("img4 height", img.height);
          setImageFour(e.target.result);
          // setTimeout(() => {
          //   if (img.width === 287 && img.height === 241) {
          //     setImageFour(e.target.result);
          //     setErrorImageFourth("");
          //   } else {
          //     setErrorImageFourth(`कृपया (287 x 241) या Size चे Images टाका`);
          //     setTimeout(() => {
          //       alert(
          //         `तुमी select केलेली height ${img.height} आणि width ${img.width}`
          //       );
          //     }, 2000);
          //   }
          // }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //   image5 handleChange

  const handleImageChange5 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          console.log("img5 width", img.width);
          console.log("img5 height", img.height);
          setImageFive(e.target.result);
          // setTimeout(() => {
          //   if (img.width === 288 && img.height === 241) {
          //     setImageFive(e.target.result);
          //     setErrorImageFifth("");
          //   } else {
          //     setErrorImageFifth(`कृपया (288 x 241) या Size चे Images टाका`);
          //     setTimeout(() => {
          //       alert(
          //         `तुमी select केलेली height ${img.height} आणि width ${img.width}`
          //       );
          //     }, 2000);
          //   }
          // }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // validation

  const validation = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!subtitle) {
      setRoleError("subtitle  is required");
      valid = false;
    } else {
      setRoleError("");
    }
    if (!description) {
      setDescriptionError("Description is required");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!youtubeUrl) {
      setYtUrlError("Youtube url is required");
      valid = false;
    } else {
      setYtUrlError("");
    }
    return valid;
  };
  // handle details of member

  useEffect(() => {
    const handleMembersDetails = async () => {
      const finalData = {
        social_work_id: id,
      };
      try {
        const response = await axios.post(
          API_BASE_URL + "api/social_work_details_by_id",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        // console.log(
        //   "response of social work details by id data",
        //   response.data
        // );
        // setEditDetails(response.data.data);
        setTitle(response.data.data.title);
        setSubtitle(response.data.data.sub_title);
        setRoleName(response.data.data.role_name);
        setDescription(response.data.data.description);
        setYoutubeUrl(response.data.data.youtube_url);
        setStatus(response.data.data.status);
        // setSelectedImage(response.data.data.image1);

        // setImageThree(response.data.data.image3);
        // setImageFour(response.data.data.image4);
        // setImageFive(response.data.data.image5);
        if (response.data.data.image1) {
          setSelectedImage1(response.data.data.image1);
        }
        if (response.data.data.image2) {
          setImageSecond1(response.data.data.image2);
        }
        if (response.data.data.image3) {
          setImageThree1(response.data.data.image3);
        }
        if (response.data.data.image4) {
          setImageFour1(response.data.data.image4);
        }
        if (response.data.data.image5) {
          setImageFive1(response.data.data.image5);
        }
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };

    handleMembersDetails();
  }, [id]);

  // edit schemes api

  const handleEditMember = async (e) => {
    e.preventDefault();
    if (validation()) {
      if (
        errorImageOne ||
        errorImageSecond ||
        errorImageThird ||
        errorImageFourth ||
        errorImageFifth
      ) {
        alert(
          errorImageOne ||
            errorImageSecond ||
            errorImageThird ||
            errorImageFourth ||
            errorImageFifth
        );
      } else {
        try {
          const finalData = {
            admin_id: userDetails.id,
            social_work_id: id,
            title: title,
            sub_title: subtitle,
            description: description,
            youtube_url: youtubeUrl,
            status: status,
            // image: selectedImage,
          };
          // if (
          //   selectedImage ||
          //   imageSecond ||
          //   imageThree ||
          //   imageFour ||
          //   imageFive
          // ) {
          //   finalData.image1 = selectedImage;
          //   finalData.image2 = imageSecond;
          //   finalData.image3 = imageThree;
          //   finalData.image4 = imageFour;
          //   finalData.image5 = imageFive;
          // }

          if (selectedImage) {
            finalData.image1 = selectedImage;
          }
          if (imageSecond) {
            finalData.image2 = imageSecond;
          }
          if (imageThree) {
            finalData.image3 = imageThree;
          }
          if (imageFour) {
            finalData.image4 = imageFour;
          }
          if (imageFive) {
            finalData.image5 = imageFive;
          }

          // console.log("edit final data", finalData);

          const response = await axios.post(
            API_BASE_URL + "api/edit_social_work",
            finalData,
            {
              auth: {
                username: API_AUTH_USERNAME,
                password: API_AUTH_PASSWORD,
              },
            }
          );
          // console.log("edit response data", response.data);
          if (response.data.status === true) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate("/social-works");
            }, 2000);
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.log("error occur in edit data", error);
        }
      }
    }
  };

  return (
    <div className="container">
      <div className="row h-100">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="input_wrappers">
            <div className="wrapper_title">
              <h4>Edit Social Work</h4>
            </div>
            <form onSubmit={handleEditMember}>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Title :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="common_input form-control"
                  />
                  {titleError && (
                    <span className="banner_error_text">{titleError}</span>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Subtitle :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                    className="common_input form-control"
                  />
                  {roleError && (
                    <span className="banner_error_text">{roleError}</span>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Description :-</label>
                </div>
                <div className="input_sec">
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={handleProductDescription}
                  />
                  {descriptionError && (
                    <span className="banner_error_text">
                      {descriptionError}
                    </span>
                  )}
                </div>
              </div>
              {/*  image 1 */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Image 1 :-</label>
                </div>
                <div className="input_sec">
                  {selectedImage1 && !selectedImage && (
                    <div className="selected_img_group">
                      <img
                        src={selectedImage1}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {selectedImage && !selectedImage1 && (
                    <div className="selected_img_group">
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {!selectedImage1 && !selectedImage && (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className={`form-control common_input`}
                      />
                      {errorImageOne && (
                        <p className="banner_error_text">{errorImageOne}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/*  image 2 */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Image 2 :-</label>
                </div>
                <div className="input_sec">
                  {imageSecond1 && !imageSecond && (
                    <div className="selected_img_group">
                      <img
                        src={imageSecond1}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove2}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {imageSecond && !imageSecond1 && (
                    <div className="selected_img_group">
                      <img
                        src={imageSecond}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove2}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {!imageSecond1 && !imageSecond && (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange2}
                        className={`form-control common_input`}
                      />
                      {errorImageSecond && (
                        <p className="banner_error_text">{errorImageSecond}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/*  image 3 */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Image 3 :-</label>
                </div>
                <div className="input_sec">
                  {imageThree1 && !imageThree && (
                    <div className="selected_img_group">
                      <img
                        src={imageThree1}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove3}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {imageThree && !imageThree1 && (
                    <div className="selected_img_group">
                      <img
                        src={imageThree}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove3}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {!imageThree1 && !imageThree && (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange3}
                        className={`form-control common_input`}
                      />
                      {errorImageThird && (
                        <p className="banner_error_text">{errorImageThird}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/*  image 4 */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Image 4 :-</label>
                </div>
                <div className="input_sec">
                  {imageFour1 && !imageFour && (
                    <div className="selected_img_group">
                      <img
                        src={imageFour1}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove4}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {imageFour && !imageFour1 && (
                    <div className="selected_img_group">
                      <img
                        src={imageFour}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove4}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {!imageFour1 && !imageFour && (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange4}
                        className={`form-control common_input`}
                      />
                      {errorImageFourth && (
                        <p className="banner_error_text">{errorImageFourth}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/*  image 5 */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Image 5 :-</label>
                </div>
                <div className="input_sec">
                  {imageFive1 && !imageFive && (
                    <div className="selected_img_group">
                      <img
                        src={imageFive1}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove5}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {imageFive && !imageFive1 && (
                    <div className="selected_img_group">
                      <img
                        src={imageFive}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove5}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {!imageFive1 && !imageFive && (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange5}
                        className={`form-control common_input`}
                      />
                      {errorImageFifth && (
                        <p className="banner_error_text">{errorImageFifth}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* youtube url */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Youtube Url :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={youtubeUrl}
                    onChange={(e) => setYoutubeUrl(e.target.value)}
                    className="common_input form-control"
                  />
                  {ytUrlError && (
                    <span className="banner_error_text">{ytUrlError}</span>
                  )}
                </div>
              </div>
              {/* <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">visible on home page :-</label>
                </div>
                <div className="input_sec">
                  <div className="status-group">
                    <div className="active-sec">
                      <input
                        type="radio"
                        name="displayOnHome"
                        value="Y"
                        checked={displayOnHome === "Y"}
                        onChange={handleDisplayOnHome}
                        className="me-2"
                      />
                      <span>Yes</span>
                    </div>
                    <div className="deactive-sec">
                      <input
                        type="radio"
                        name="displayOnHome"
                        value="N"
                        checked={displayOnHome === "N"}
                        onChange={handleDisplayOnHome}
                        className="me-2"
                      />
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">Status :-</label>
                </div>
                <div className="input_sec">
                  <div className="status-group">
                    <div className="active-sec">
                      <input
                        type="radio"
                        name="status"
                        value="Y"
                        checked={status === "Y"}
                        onChange={handleStatus}
                        className="me-2"
                      />
                      <span>Active</span>
                    </div>
                    <div className="deactive-sec">
                      <input
                        type="radio"
                        name="status"
                        value="N"
                        checked={status === "N"}
                        onChange={handleStatus}
                        className="me-2"
                      />
                      <span>DeActive</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input_group">
                <button className="input_add_btn">Save</button>
              </div>
            </form>
            {/* <div className="note_sec text-center">
              <p className="note_para">
                Note :-{" "}
                <span className="note_span">
                  कृपया या (360 x 200) Size चे व यापेक्षा जास्त Size चे Banner
                  टाका
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default EditSocialWork;
