import React, { useEffect, useState } from "react";
import "../banner/homeBanner/homeBanner.css";
import "./contact.css";
import "../ourGrampanchayat/ourGram.css";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [bannerData, setBannerData] = useState([]);

  const [contactData, setContactData] = useState({
    mapUrl: "",
    address: "",
    mobile: "",
    email: "",
    webLink: "",
  });

  const [errors, setErrors] = useState({
    mapUrl: "",
    address: "",
    mobile: "",
    email: "",
    webLink: "",
  });

  const handleContactData = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        value.trim() !== ""
          ? ""
          : `${name.charAt(0).toUpperCase() + name.slice(1)} is required *`,
    }));
  };

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  // validation

  const validation = () => {
    let valid = true;
    const newErrors = {
      mapUrl: "",
      address: "",
      mobile: "",
      email: "",
      webLink: "",
    };

    // map url validation
    if (contactData.mapUrl.trim() === "") {
      newErrors.mapUrl = "कृपया नकाशा url टाका*";
      valid = false;
    }

    // address validation
    if (contactData.address.trim() === "") {
      newErrors.address = "कृपया पत्ता टाका*";
      valid = false;
    }

    // mobile validation
    if (contactData.mobile.trim() === "") {
      newErrors.mobile = "कृपया मोबाइल नंबर टाका*";
      valid = false;
    }

    // mobile validation
    if (contactData.email.trim() === "") {
      newErrors.email = "कृपया ईमेल टाका*";
      valid = false;
    }

    // mobile validation
    if (contactData.webLink.trim() === "") {
      newErrors.webLink = "कृपया वेबसाइट लिंक टाका*";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    handleBannerData();
  }, []);

  //   home banner api

  const handleBannerData = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/contact_us",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("contact response", response.data);
      setBannerData(response.data.data);

      setContactData({
        mapUrl: response.data.data.map_url,
        address: response.data.data.address,
        mobile: response.data.data.mobile,
        email: response.data.data.email,
        webLink: response.data.data.website_link,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // banner add  api

  const handleAddBanner = async (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        const { mapUrl, address, mobile, email, webLink } = contactData;
        const data = {
          admin_id: userDetails.id,
          map_url: mapUrl,
          address: address,
          mobile: mobile,
          email: email,
          website_link: webLink,
        };
        console.log("update contact finalData", data);

        const response = await axios.post(
          API_BASE_URL + "api/update_contact_us",
          data,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("update contact response", response.data);
        if (response.data.status === true) {
          toast.success(response.data.message);
          const data = response.data.data;
          setBannerData(data);
          // setContactData({
          //   mapUrl: "",
          //   address: "",
          //   mobile: "",
          //   email: "",
          //   webLink: "",
          // });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log("error occurred in add banner", error);
      }
    }
  };

  return (
    <div className="container">
      <div className="row h-100">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="banner_wrapper">
            <div className="banner_header_top">
              <h4>संपर्क</h4>
            </div>
            <div className="banner_body">
              <form onSubmit={handleAddBanner}>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label className="input_label">नकाशा Url :-</label>
                      <input
                        type="text"
                        name="mapUrl"
                        value={contactData.mapUrl}
                        onChange={handleContactData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.mapUrl && (
                        <p className="banner_error_text">{errors.mapUrl}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label className="input_label">पत्ता :-</label>
                      <input
                        type="text"
                        name="address"
                        value={contactData.address}
                        onChange={handleContactData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.address && (
                        <p className="banner_error_text">{errors.address}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label className="input_label">मोबाईल नं :-</label>
                      <input
                        type="text"
                        name="mobile"
                        value={contactData.mobile}
                        onChange={handleContactData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.mobile && (
                        <p className="banner_error_text">{errors.mobile}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label className="input_label">ईमेल :-</label>
                      <input
                        type="text"
                        name="email"
                        value={contactData.email}
                        onChange={handleContactData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.email && (
                        <p className="banner_error_text">{errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="banner_input_group">
                      <label className="input_label">वेबसाइट लिंक :-</label>
                      <input
                        type="text"
                        name="webLink"
                        value={contactData.webLink}
                        onChange={handleContactData}
                        className={`form-control contact_common_inputs`}
                      />
                      {errors.webLink && (
                        <p className="banner_error_text">{errors.webLink}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="banner_input_group d-flex justify-content-end mt-4 mb-4">
                  <button className="input_add_btn">माहिती बदला</button>
                </div>
              </form>
              <ul className="banner_ul_list">
                {bannerData && Object.keys(bannerData).length > 0 ? (
                  <li className="banner_list">
                    <div className="description_our_village_sec">
                      <h6 className="our_village_title">
                        नकाशा <span>Url</span> :-
                      </h6>
                      <p className="contact_data_para">{bannerData.map_url}</p>
                    </div>
                    <div className="description_our_village_sec">
                      <h6 className="our_village_title">पत्ता :-</h6>
                      <p className="contact_data_para">{bannerData.address}</p>
                    </div>
                    <div className="description_our_village_sec">
                      <h6 className="our_village_title">मोबाईल नं :-</h6>
                      <p className="contact_data_para">{bannerData.mobile}</p>
                    </div>
                    <div className="description_our_village_sec">
                      <h6 className="our_village_title">ईमेल :-</h6>
                      <p className="contact_data_para">{bannerData.email}</p>
                    </div>
                    <div className="description_our_village_sec">
                      <h6 className="our_village_title">वेबसाइट लिंक :-</h6>
                      <p className="contact_data_para">
                        {bannerData.website_link}
                      </p>
                    </div>
                  </li>
                ) : (
                  <li className="banner_list">No banner available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default Contact;
