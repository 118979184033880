import React, { useEffect, useState } from "react";
import "../homeBanner/homeBanner.css";
import { MdDelete } from "react-icons/md";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MobileHomeBanner = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [bannerData, setBannerData] = useState([]);

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleImageRemove = () => {
    setSelectedImage(null);
    setErrorMessage("");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          setTimeout(() => {
            if (img.width === 400 && img.height === 250) {
              //   console.log("img width", img.width);
              //   console.log("img height", img.height);
              setSelectedImage(e.target.result);
              setErrorMessage("");
            } else {
              setErrorMessage(`कृपया (400 x 250) या Size चे Banner टाका`);
              //   alert(`width ${img.width} height ${img.height}`);
            }
          }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //   home banner api

  const handleBannerData = async (e) => {
    try {
      const finalData = { admin_id: userDetails.id };
      //   console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/mobile_home_banner",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("mobile home banner response", response.data);
      setBannerData(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    handleBannerData();
  }, []);

  const handleRefresh = async () => {
    await handleBannerData();
  };

  // banner add  api

  const handleAddBanner = async (e) => {
    e.preventDefault();
    try {
      if (errorMessage) {
        alert(`कृपया (400 x 250) या Size चे Banner टाका`);
        return;
      }
      const data = {
        admin_id: userDetails.id,
        image: selectedImage,
      };
      const response = await axios.post(
        API_BASE_URL + "api/mobile_update_home_banner",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("add banner response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        const newImage = response.data.data; // Assuming the API response contains the newly added image data
        setBannerData([newImage, ...bannerData]); // Update local state with the new image
        setSelectedImage("");
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occurred in add banner", error);
    }
  };

  //   Delete product image api

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure want to delete this banner ?");
    if (!confirm) {
      return;
    }
    try {
      const finalData = { banner_id: id };
      const response = await axios.post(
        API_BASE_URL + "api/mobile_delete_home_banner",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      const updatedImage = bannerData.filter((item) => item.id !== id);
      setBannerData(updatedImage);
    } catch (error) {
      console.log("error occuring delete iamge");
    }
  };

  return (
    <div className="container">
      <div className="row h-100">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="banner_wrapper">
            <div className="banner_header_top">
              <h4>मोबाइल होम बॅनर</h4>
            </div>
            <div className="banner_body">
              <form onSubmit={handleAddBanner}>
                <div className="banner_input_group">
                  <label className="input_label">
                    बॅनर निवडा :-{" "}
                    <span>कृपया (400 x 250) या Size चे Banner टाका</span>
                  </label>
                  {selectedImage ? (
                    <div className="selected_img_group">
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/webp"
                        onChange={handleImageChange}
                        className={`form-control edit_c_cat_input`}
                      />
                      {errorMessage && (
                        <p className="banner_error_text">{errorMessage}</p>
                      )}
                    </>
                  )}
                </div>
                <div className="banner_input_group d-flex justify-content-end mt-4 mb-4">
                  <button className="input_add_btn">बॅनर अ‍ॅड करा</button>
                </div>
              </form>
              <ul className="banner_ul_list">
                {bannerData && Object.keys(bannerData).length > 0 ? (
                  bannerData.map((ele) => {
                    return (
                      <li className="banner_list" key={ele.id}>
                        <img
                          src={ele.banner}
                          alt="banners"
                          className="img-fluid"
                        />
                        <div
                          className="banner_delete_sec"
                          onClick={() => handleDelete(ele.id)}
                        >
                          <MdDelete />
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li className="banner_list">No banner available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default MobileHomeBanner;
