import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditMember = () => {
  const { id } = useParams("");
  const navigate = useNavigate("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleError, setRoleError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [displayOnHome, setDisplayOnHome] = useState("Y");
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("Y");

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handleDisplayOnHome = (e) => {
    setDisplayOnHome(e.target.value);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
    setErrorMessage("");
  };

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          setSelectedImage(e.target.result);
          // console.log("img width", img.width);
          // console.log("img height", img.height);
          // setTimeout(() => {
          //   if (img.width === 193 && img.height === 218) {
          //     console.log("img width", img.width);
          //     console.log("img height", img.height);
          //     setSelectedImage(e.target.result);
          //     setErrorMessage("");
          //   } else {
          //     setErrorMessage(`कृपया (193 x 218) या Size चे Images टाका`);
          //   }
          // }, 100);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // validation

  const validation = () => {
    let valid = true;
    if (!title) {
      setTitleError("Title is required");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!roleName) {
      setRoleError("Role Name is required");
      valid = false;
    } else {
      setRoleError("");
    }
    if (!description) {
      setDescriptionError("Description is required");
      valid = false;
    } else {
      setDescriptionError("");
    }
    return valid;
  };

  // handle details of member

  useEffect(() => {
    const handleMembersDetails = async () => {
      const finalData = {
        member_id: id,
      };
      try {
        const response = await axios.post(
          API_BASE_URL + "api/member_details_by_id",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("response of member details by id data", response.data);
        // setEditDetails(response.data.data);
        setTitle(response.data.data.title);
        setRoleName(response.data.data.role_name);
        setDescription(response.data.data.description);
        setDisplayOnHome(response.data.data.display_on_home);
        if (response.data.data.image) {
          setSelectedImage1(response.data.data.image);
        }
        setStatus(response.data.data.status);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };

    handleMembersDetails();
  }, [id]);

  // edit schemes api

  const handleEditMember = async (e) => {
    e.preventDefault();
    if (validation()) {
      if (errorMessage) {
        alert(errorMessage);
      } else {
        try {
          const finalData = {
            admin_id: userDetails.id,
            member_id: id,
            title: title,
            role_name: roleName,
            description: description,
            display_on_home: displayOnHome,
            status: status,
            // image: selectedImage,
          };
          if (selectedImage) {
            finalData.image = selectedImage;
          }
          console.log("edit final data", finalData);

          const response = await axios.post(
            API_BASE_URL + "api/edit_member",
            finalData,
            {
              auth: {
                username: API_AUTH_USERNAME,
                password: API_AUTH_PASSWORD,
              },
            }
          );
          console.log("edit response data", response.data);
          if (response.data.status === true) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate("/members");
            }, 2000);
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.log("error occur in edit data", error);
        }
      }
    }
  };

  return (
    <div className="container">
      <div className="row h-100">
        <div className="col-lg-12 d-flex justify-content-center align-items-center">
          <div className="input_wrappers">
            <div className="wrapper_title">
              <h4>एडिट सदस्य</h4>
            </div>
            <form onSubmit={handleEditMember}>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">फोटो :-</label>
                </div>
                <div className="input_sec">
                  {selectedImage1 && !selectedImage && (
                    <div className="selected_img_group">
                      <img
                        src={selectedImage1}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {selectedImage && !selectedImage1 && (
                    <div className="selected_img_group">
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="img-fluid"
                      />
                      <button
                        onClick={handleImageRemove}
                        className={`btn remove_banner_btn`}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {!selectedImage1 && !selectedImage && (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className={`form-control common_input`}
                      />
                      {errorMessage && (
                        <p className="banner_error_text">{errorMessage}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">नाव :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="common_input form-control"
                  />
                  {titleError && (
                    <span className="banner_error_text">{titleError}</span>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">रोलचे नाव :-</label>
                </div>
                <div className="input_sec">
                  <input
                    type="text"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    className="common_input form-control"
                  />
                  {roleError && (
                    <span className="banner_error_text">{roleError}</span>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">वर्णन :-</label>
                </div>
                <div className="input_sec">
                  {/* <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="common_input form-control"
                  /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={handleProductDescription}
                  />
                  {descriptionError && (
                    <span className="banner_error_text">
                      {descriptionError}
                    </span>
                  )}
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">होमपेजला दाखवा :-</label>
                </div>
                <div className="input_sec">
                  <div className="status-group">
                    <div className="active-sec">
                      <input
                        type="radio"
                        name="displayOnHome"
                        value="Y"
                        checked={displayOnHome === "Y"}
                        onChange={handleDisplayOnHome}
                        className="me-2"
                      />
                      <span>होय</span>
                    </div>
                    <div className="deactive-sec">
                      <input
                        type="radio"
                        name="displayOnHome"
                        value="N"
                        checked={displayOnHome === "N"}
                        onChange={handleDisplayOnHome}
                        className="me-2"
                      />
                      <span>नाही</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input_group">
                <div className="label_sec">
                  <label className="input_label">स्टेटस :-</label>
                </div>
                <div className="input_sec">
                  <div className="status-group">
                    <div className="active-sec">
                      <input
                        type="radio"
                        name="status"
                        value="Y"
                        checked={status === "Y"}
                        onChange={handleStatus}
                        className="me-2"
                      />
                      <span>अ‍ॅक्टिव</span>
                    </div>
                    <div className="deactive-sec">
                      <input
                        type="radio"
                        name="status"
                        value="N"
                        checked={status === "N"}
                        onChange={handleStatus}
                        className="me-2"
                      />
                      <span>डीअ‍ॅक्टिव</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input_group">
                <button className="input_add_btn">Save</button>
              </div>
            </form>
            {/* <div className="note_sec text-center">
              <p className="note_para">
                Note :-{" "}
                <span className="note_span">
                  कृपया (193 x 218) या Size चे Images टाका
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default EditMember;
