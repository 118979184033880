import React from "react";
import "./Navbar.css";
import "../../assets/css/main.css";
import { IoMdHome } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaCircleArrowLeft } from "react-icons/fa6";

const Navbar = ({ sidebarOpen, setSideBarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const logOut = () => {
    sessionStorage.removeItem("info");
    navigate("/login");
  };

  const handleToggle = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  return (
    <nav className="navbar navbar-expand-lg bg-navbar">
      <div className="container-fluid">
        {location.pathname === "/dashboard" && (
          <Link className="sidebar_link_btn" onClick={handleToggle}>
            {sidebarOpen ? <GiHamburgerMenu /> : <FaCircleArrowLeft />}
          </Link>
        )}
        <Link className="grampanchayat_report_title_top" to="/dashboard">
          ग्रामपंचायत रिपोर्ट सॉफ्टवेअर
        </Link>
        {/* <Link className="nav-link" to="/dashboard">
          <IoMdHome style={{ color: "white", fontSize: "24px" }} />
        </Link> */}
        <button
          className="navbar-toggler"
          type="button"
          style={{ color: "white" }}
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse text-white navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item d-flex justify-content-center align-items-center">
              <Link className="welcome_user_text_nav" to="#">
                Welcome to,{" "}
                {userDetails && <span>{userDetails.village_name}</span>}
              </Link>
            </li>
            <ul className="nav-item profile_dropdown_nav dropdown">
              {/* <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <mask
                    id="mask0_15_464"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                  >
                    <rect width="39.5" height="39.5" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_15_464)">
                    <path
                      d="M9.62796 28.1438C11.0269 27.074 12.5905 26.2305 14.3186 25.6133C16.0467 24.9961 17.8571 24.6875 19.7498 24.6875C21.6425 24.6875 23.453 24.9961 25.1811 25.6133C26.9092 26.2305 28.4728 27.074 29.8717 28.1438C30.8318 27.0191 31.5793 25.7436 32.1142 24.3172C32.6491 22.8908 32.9165 21.3684 32.9165 19.75C32.9165 16.1017 31.6341 12.9952 29.0694 10.4305C26.5046 7.86572 23.3981 6.58334 19.7498 6.58334C16.1016 6.58334 12.9951 7.86572 10.4303 10.4305C7.86555 12.9952 6.58317 16.1017 6.58317 19.75C6.58317 21.3684 6.85062 22.8908 7.38551 24.3172C7.92041 25.7436 8.66789 27.0191 9.62796 28.1438ZM19.7498 21.3958C18.1314 21.3958 16.7668 20.8404 15.6558 19.7294C14.5449 18.6185 13.9894 17.2538 13.9894 15.6354C13.9894 14.017 14.5449 12.6523 15.6558 11.5414C16.7668 10.4305 18.1314 9.87501 19.7498 9.87501C21.3682 9.87501 22.7329 10.4305 23.8438 11.5414C24.9548 12.6523 25.5103 14.017 25.5103 15.6354C25.5103 17.2538 24.9548 18.6185 23.8438 19.7294C22.7329 20.8404 21.3682 21.3958 19.7498 21.3958ZM19.7498 36.2083C17.4731 36.2083 15.3335 35.7763 13.3311 34.9122C11.3287 34.0482 9.58682 32.8755 8.10557 31.3943C6.62432 29.913 5.45166 28.1712 4.5876 26.1688C3.72354 24.1663 3.2915 22.0267 3.2915 19.75C3.2915 17.4733 3.72354 15.3337 4.5876 13.3313C5.45166 11.3288 6.62432 9.58698 8.10557 8.10573C9.58682 6.62448 11.3287 5.45183 13.3311 4.58777C15.3335 3.7237 17.4731 3.29167 19.7498 3.29167C22.0266 3.29167 24.1662 3.7237 26.1686 4.58777C28.171 5.45183 29.9129 6.62448 31.3941 8.10573C32.8754 9.58698 34.048 11.3288 34.9121 13.3313C35.7761 15.3337 36.2082 17.4733 36.2082 19.75C36.2082 22.0267 35.7761 24.1663 34.9121 26.1688C34.048 28.1712 32.8754 29.913 31.3941 31.3943C29.9129 32.8755 28.171 34.0482 26.1686 34.9122C24.1662 35.7763 22.0266 36.2083 19.7498 36.2083Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </span> */}
              <DropdownButton
                id="dropdown-basic-button"
                className="navbar-dropdown-profile fw-bold"
                title="माझे अकाऊंट"
              >
                {/* <Link
                  to="/profile"
                  className="text-start nav-drop-items text-decoration-none"
                >
                  <li className="ps-4 text-dark-emphasis">Profile</li>
                </Link> */}
                <Link
                  to="/"
                  className="text-start nav-drop-items text-decoration-none"
                >
                  <li className="ps-4 text-dark-emphasis" onClick={logOut}>
                    Log Out
                  </li>
                </Link>
              </DropdownButton>
            </ul>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
