/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./Sidebar.css"; // Import the CSS file
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";

const Sidebar = ({ sidebarOpen, setSideBarOpen }) => {
  const navigate = useNavigate();
  const [isAboutOpen, setAboutIsOpen] = useState(false);
  const [isJanOpen, setIsJanOpen] = useState(false);
  const [newsOpen, setNewsOpen] = useState(false);
  const [janIcon, setJanIcon] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      console.log("Please Login First");
      navigate("/");
    }
  }, []);

  return (
    <div className={` ${sidebarOpen ? "side_bar_hide " : "sidebar"} `}>
      <ul className="nav flex-column">
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            // title="वार्षिक प्रशासन अहवाल विभाग"
            to="/dashboard"
          >
            मुखपृष्ट
          </Link>
        </li>
        <li className="nav-item">
          <button
            className="nav-link arrow_btn_group form-control text-start"
            onClick={() => setIsJanOpen(!isJanOpen)}
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="बॅनर(Banner)"
          >
            <span className="text_overflow_span me-3">
              बॅनर <span className="nav_link_span_poppins">(Banner)</span>{" "}
            </span>

            <span onClick={() => setJanIcon(!janIcon)}>
              {" "}
              {isJanOpen ? <MdKeyboardArrowRight /> : <MdKeyboardArrowDown />}
            </span>
          </button>
          {isJanOpen && (
            <ul className="sidebar_list pt-1 pb-1">
              <Link to="/home_banner">
                <li className="pt-2 pb-2">होम बॅनर</li>
              </Link>
              <hr className="sidebar-hr" />
              <Link to="/schemes_banner">
                <li className="pt-2 pb-2">योजना बॅनर</li>
              </Link>
              <Link to="/mobile_home_banner">
                <li className="pt-2 pb-2">मोबाइल होम बॅनर</li>
              </Link>
              <Link to="/mobile_schemes_banner">
                <li className="pt-2 pb-2">मोबाइल योजना बॅनर</li>
              </Link>
              <hr className="sidebar-hr" />
            </ul>
          )}
        </li>
        <li className="nav-item">
          <button
            className="nav-link arrow_btn_group form-control text-start"
            onClick={() => setAboutIsOpen(!isAboutOpen)}
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="बॅनर(Banner)"
          >
            <span className="text_overflow_span me-3">
              <span className="nav_link_span_poppins">आमच्या विषयी</span>{" "}
            </span>

            <span onClick={() => setJanIcon(!janIcon)}>
              {" "}
              {isAboutOpen ? <MdKeyboardArrowRight /> : <MdKeyboardArrowDown />}
            </span>
          </button>
          {isAboutOpen && (
            <ul className="sidebar_list pt-1 pb-1">
              <Link to="/purpose-list">
                <li className="pt-2 pb-2">उद्देश</li>
              </Link>
              <hr className="sidebar-hr" />
              <Link to="/our-goal">
                <li className="pt-2 pb-2">आमचे ध्येय</li>
              </Link>
              <hr className="sidebar-hr" />
            </ul>
          )}
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="ग्रामपंचायत योजना"
            to="/schemes"
          >
            ग्रामपंचायत योजना
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="ग्रामपंचायत सदस्य"
            to="/members"
          >
            ग्रामपंचायत सदस्य
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="प्रमुख स्थळे"
            to="/imp-places"
          >
            प्रमुख स्थळे
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="आपली ग्रामपंचायत"
            to="/our-grampanchayat"
          >
            आपली ग्रामपंचायत
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="सार्वजनिक सुविधा"
            to="/public-facilities"
          >
            सार्वजनिक सुविधा
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="संपर्क"
            to="/contact"
          >
            संपर्क
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="आपत्कालीन संपर्क"
            to="/emergency-contact"
          >
            आपत्कालीन संपर्क
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="को-पार्टनर"
            to="/our-partners"
          >
            को-पार्टनर
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="ग्रामपंचायत पुरस्कार"
            to="/social-awards"
          >
            ग्रामपंचायत पुरस्कार
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="समाजकार्य"
            to="/social-works"
          >
            समाजकार्य
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="ग्रामपंचायत दृष्टीक्षेप"
            to="/village-summary"
          >
            ग्रामपंचायत दृष्टीक्षेप
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="फोटो/व्हिडीओ"
            to="/photo-video-list"
          >
            फोटो/व्हिडीओ
          </Link>
        </li>
        <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Social Links"
            to="/social-links"
          >
            <span className="text_overflow_span me-3">
              <span className="nav_link_span_poppins">Social Links</span>{" "}
            </span>
          </Link>
        </li>
        {/* <li className="nav-item sidebar-item">
          <Link
            className="nav-link"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="प्रशासकीय कार्ये"
            to="/latest-news-list"
          >
            प्रशासकीय कार्ये
          </Link>
        </li> */}
        <li className="nav-item">
          <button
            className="nav-link arrow_btn_group form-control text-start"
            onClick={() => setNewsOpen(!newsOpen)}
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="प्रशासकीय कार्ये"
          >
            <span className="text_overflow_span me-3">
              <span className="nav_link_span_poppins"> प्रशासकीय कार्ये</span>{" "}
            </span>

            <span onClick={() => setNewsOpen(!newsOpen)}>
              {" "}
              {newsOpen ? <MdKeyboardArrowRight /> : <MdKeyboardArrowDown />}
            </span>
          </button>
          {newsOpen && (
            <ul className="sidebar_list pt-1 pb-1">
              <Link to="/latest-news-list">
                <li className="pt-2 pb-2">ताज्या बातम्या</li>
              </Link>
              <hr className="sidebar-hr" />
              <Link to="/what-is-new">
                <li className="pt-2 pb-2">नवीन काय आहे</li>
              </Link>
              <hr className="sidebar-hr" />
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
