import React from "react";

const PageNotFound = () => {
  return (
    <section
      className="d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <div className="coming-soon">
              <h1 className="fw-bold">404 Page Not Found</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
